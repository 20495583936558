@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$robot: 'Roboto', sans-serif;
$main: #0fbdfa;

$dark: #0d1525;
$not-black: #0d1525;
$orange: #f79e1cdb;
$light-gray: rgb(204, 204, 204);
$black: #1b1b1b;
$gray: #ececec;
$accent: #F29E16;

$main-color: #288fd8;
$main-subcolor: #f0f6f9;
$main-light-dark: rgb(158, 158, 158);
$main-light-gray: #d6e1e4;
$text: rgb(87, 87, 87);
$text-dark: rgb(45, 50, 68);
$green: #8aa93c;
$red: #9f1e1e;