@import 'mixins';

*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html{
    scroll-behavior: smooth;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: $robot;
}

.section-search-brand{
    border: none;
    width: calc(100% - 20px);
    padding: 0px 10px 0px 10px;
    background-color: darken(white, 5%);
    height: 50px;
    margin-top: 15px;
    border-radius: 3px;
    // background-color: plum;
    outline: none;
}

.new-sort{
    width: 100%;
    // background-color: pink;
    @include flexRow(flex-start);
    margin-top: 10px;

    &__sort-text{
        font-weight: 500;
    }

    &__sort-button{
        margin-left: 10px;
        // color: ;
        cursor: pointer;
        &:hover{
            color: $accent;
        }
    }
}

.up-button{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background-color:$accent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: calc(100% - 100px);
    left: calc(100% - 100px);
    z-index: 100;
    cursor: pointer;
    display:none;

    &__svg-container{
        width: 30px;
        height: 30px;
        border-radius: 65px;
        // background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    svg{
        // background-color:#fff;
        width: 30px;
        height: 30px;
        path{
            fill: white;
        }
    }

    &:hover{
        background-color: darken($accent , 5%);
        svg{
            transform: scale(1.1);
        }
    }
}

.action-button{
    cursor: pointer;
}

.tags-block{
    width: calc(100% - 20px);
    padding: 10px;
    background-color: red;
    margin-top: 20px;
    background-color: darken(white, 3%);
    box-shadow: 0px 0px 4px lightgray;
    border-radius: 5px;

    &__header{
        font-size: 18px;
    }

    &__sep{
        width: 100%;
        height: 2px;
        background-color: $accent;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 5px;
    }

    &__tags-container{
        width: 100%;
        // background-color: pink;
        @include flexRow(flex-start);
        align-items: flex-start;
        flex-wrap: wrap;
    }

    &__tag{
        margin: 10px;
        background-color: $accent;
        padding: 5px;
        font-size: 14px;
        text-decoration: none;
        position: relative;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    
}

.same-block{
    width: calc(100% - 20px);
    padding: 10px;
    background-color: red;
    margin-top: 20px;
    background-color: darken(white, 3%);
    box-shadow: 0px 0px 4px lightgray;
    border-radius: 5px;

    &__header{
        font-size: 18px;
    }

    &__sep{
        width: 100%;
        height: 2px;
        background-color: $accent;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 5px;
    }

    &__items-container{
        width: 100%;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__article{
        margin: 4px;
        width: 300px;
        height: 300px;
        border-radius: 315px;
        // background-color: plum;
        position: relative;
        // margin: 12px;
        // border-radius: 3px;
        overflow: hidden;
        text-decoration: none;
        background-color: $accent;
        transition: 0.2s;
        @include flexRow(center);
        align-items: center;
        // box-shadow: 0px 0px 10px darken($accent, 5%);
        &:hover{
            box-shadow: 0px 0px 15px darken($accent, 5%);
            // top: -3px;
        }
    }


    &__content-block{
        // width: calc(100% - 250px);
        // height: calc(100% - 60px);
        width: 275px;
        height: 275px;
        border-radius: 275px;
        // padding: 30px;
        // background-color: rgb(255, 166, 0);
        // background-color: rgba(0, 0, 0, 0.308);
        position: absolute;
        @include flexColumn(center);
        // background-color: pink;
        background-color: darken($accent, 5%);
        box-shadow: 0px 0px 10px darken($accent, 5%);
        overflow: hidden;
        // z-index: 2;
    }

    &__stat-block{
        width: 100%;
        height: 20px;
        // background-color: white;
        border-radius: 5px;
        @include flexRow(center);
        // background-color: plum;
        margin-top: 10px;
    }

    &__time-block{
        width: 80px;
        margin: 0px 5px 0px 5px;
        height: 20px;
        background-color: white;
        @include flexRow(center);
        align-items: center;
        border-radius: 5px;
        color: black;
    }

    &__stats-text{
        margin-left: 5px;
    }

    &__article-header{
        color: white;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        // background-color: plum;
        height: 100px;
        @include flexColumn(center);
        align-items: center;
        width: calc(100% - 20px);
        font-weight: 500;
        letter-spacing: 0.7px;
        padding: 0px 10px 0px 10px;


    }

    &__advice{
        margin: 10px 0px 10px 0px;
        // max-width: 400px;
        width: calc(100% - 40px);
        padding: 20px;
        // height: 315px;
        // background-color: plum;
        background-color: darken(white, 5%);
        // border-radius: 315px;
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        text-decoration: none;
        transition: 0.2s;
        @include flexRow(center);
        align-items: center;
        &:hover{
            // top: -3px;
            box-shadow: 0px 0px 10px lightgray;
        }
        &:nth-child(even){
            // background-color: red;
            .same-block__advice-img-block{
                order: 2;
                border-left: 3px solid $accent;
                border-right: none;
                @include flexRow(center);
                align-items: center;
                padding: 0px 0px 0px 10px;  
            }

            .same-block__texts-block{
                margin-right: 20px;
                margin-left: 0px;
            }
        }
    }

    &__advice-content-block{
        width: 100%;
        height: 100%;
        // background-color: rgba(0, 0, 0, 0.404);
        // position: absolute;
        // background-color: red;
        text-align: center;
        overflow: hidden;
        @include flexRow(flex-start);
        align-items: center;
        // z-index: 2;
    }

    &__advice-img-block{
        width: 200px;
        max-height: 190px;
        height: 100%;
        // background-color: yellow;
        border-right: 3px solid $accent;
        @include flexRow(center);
        align-items: center;
        padding: 0px 10px 0px 0px;
    }

    &__advice-img{
        max-width: 80%;
        max-height: 80%;
    }
    

    &__texts-block{
        // width: 100%;
        width: calc(100% - 235px);
        margin-left: 20px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__advice-number{
        // background-color: pink;
        width: 100%;
        font-size: 20px;
        color: black;
        text-align: start;
        height: 25px;
    }

    &__advice-header{
        color: $accent;
    }

    &__advice-text{
        // background-color: pink;
        // color: white;
        font-weight: 400;
        font-size: 14px;
        margin-top: 15px;
        width: calc(100% - 20px);
        margin-left: 20px;
        // margin-top: 20px;
        // background-color: pink;
        color: black;
        max-height: 150px;
        height: calc(100% - 40px);
        overflow: hidden;
        letter-spacing: 0.7px;
        line-height: 22px;
    }
}

.main-section{
    width: 100%;
    height: 550px;
    // background-color: red;
    @include flexRow(center);
    position: relative;
    
    &__container{
        // max-width: 1360px;
        width: 100%;
        height: 100%;
        // background-color: red;
        // position: absolute;
        position: relative;
        @include flexRow(center);
    }
    
    &__main-slide{
        width: 100%;
        height: 100%;
        // background-color: yellow;
        // position: relative;
        @include flexRow(center);
        align-items: center;
    }

    &__img-block{
        width: 100%;
        height: 100%;
        // background-color: pink;
        // overflow: hidden;
    }
    
    &__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        // transition: 0.3s;
        // background-position: center;
    }

    &__content-block{
        // max-width: 800px;
        // width: 100%;
        width: 100%;
        height: 100%;
        @include flexColumn(center);
        background-color: rgba(0, 0, 0, 0.178);
        // background-color: yellow;
        align-items: center;
        position: absolute;
    }

    &__header{
        // margin-top: 140px;
        font-size: 46px;
        color: white;
    }

    &__buttons-block{
        // width: 100%;
        height: 50px;
        // background-color: pink;
        // background-color: rgba(0, 0, 0, 0.678);
        padding: 10px;
        margin-top: 100px;
        @include flexRow(center);
        // box-shadow: 0px 0px 80px 3px black;
    }

    &__call-button{
        width: 200px;
        height: 46px;
        // background-color: plum;
        border: 2px solid white;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: white;
        margin-right: 25px;
        transition: 0.1s;
        cursor: pointer;
        &:hover{
            background-color: white;
            color: $black;
        }
        &:active{
            transform: scale(0.9);
        }
    }

    &__req-button{
        width: 204px;
        height: 50px;
        background-color: $accent;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: white;
        cursor: pointer;
        transition: 0.1s;
        &:hover {
            background-color: darken($accent, 5%);
        }
        &:active{
            transform: scale(0.9);
        }
    }
}

.brands-line{
    width: 100%;
    height: 120px;
    padding: 75px 0px 75px 0px;
    background-color: white;
    @include flexRow(center);
    border-bottom: 1px solid $light-gray;

    &__container{
        max-width: 1360px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexRow(space-between);
        align-items: center;
    }

    &__slider{
        max-width: 1260px;
        width: 100%;
        height: 100%;
        // background-color: pink;
        @include flexRow(space-between);
        align-items: center;
    }

    &__control-button{
        width: 40px;
        height: 40px;
        // background-color: lighten($black, 20%);
        @include flexRow(center);
        align-items: center;
        border-radius: 3px;
        transition: 0.3s;
        cursor: pointer;
        svg{
            width: 20px;
            height: 20px;
            path{
                fill: $light-gray;
                transition: 0.3s;
            }
        }
        &:hover{
            background-color: $accent;
            svg{
                path{
                    fill: white;
                }
            }
        }
    }

    &__control-button_deactive{
        background-color: lighten($black, 7%);
        pointer-events: none;
    }

    &__slide{
        width: 100%;
        height: 100%;
        // background-color: plum;
        @include flexRow(space-between);
    }

    &__item{
        width: 150px;
        height: 120px;
        // background-color: orange;
        @include flexRow(center);
        align-items: center;
        transition: 0.3s;
        border-radius: 5px;
        &:hover{
            background-color: darken(white, 3%);
        }
    }

    &__img{
        max-width: 90%;
        max-height: 90%;
    }
}

.cats-section{
    width: 100%;
    // height: 500px;
    // background-color: red;
    // background-color: white;
    background-color: darken(white, 3%);
    padding: 50px 0px 50px 0px;
    @include flexRow(center);
    align-items: center;

    &__container{
        max-width: 1360px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(center);
    }

    &__cats-container{
        width: 100%;
        height: 790px;
        overflow: hidden;
        // background-color: pink;
        list-style: none;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__cat{
        // background-color: plum;
        // background-color: darken(white, 3%);
        background-color: white;
        margin: 15px;
        border-radius: 3px;
        // @include flexRow(space-between);
        transition: 0.3s;
        &:hover{
            // background-color: darken(white, 10%);
            box-shadow: 0px 0px 10px darken(white, 16%);
            .cats-section__img{
                transform: scale(1.1);
            }
        }
    }
    
    &__cat-link{
        width: 600px;
        height: 310px;
        padding: 25px;
        @include flexRow(space-between);
        // width: 100%;
        // height: 100%;
        // background-color: orange;
        text-decoration: none;
    }

    &__content-block{
        width: 320px;
        height: 100%;
        // background-color: aqua;
        @include flexColumn(center);
        align-items: center;
    }
    
    &__name{
        // margin-top: 100px;
        color: $black;
        font-size: 24px;
        font-weight: 400;
    }

    &__price{
        // background-color: pink;
        margin-top: 20px;
        @include flexRow(center);
        align-items: center;
        color: $black;
    }

    &__price-accent{
        color: $accent;
        margin: 0px 5px 0px 5px;
        font-size: 22px;
    }

    &__img-block{
        width: 270px;
        height: 100%;
        // background-color: aqua;
        @include flexRow(center);
        align-items: center;
    }

    &__img{
        transition: 0.3s;
        max-width: 100%;
        max-height: 100%;
    }

    &__button-block{
        width: 100%;
        height: 50px;
        margin-top: 20px;
        // background-color: pink;
        @include flexRow(center);
        align-items: center;
    }

    &__show-button{
        width: 250px;
        height: 40px;
        background-color: darken(white, 10%);
        border-radius: 5px;
        box-shadow: 0px 0px 4px lightgray;
        text-align: center;
        @include flexColumn(center);
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            box-shadow: 0px 0px 4px rgb(170, 170, 170);
            // background-color: darken(white, 15%);
        }
    }
}

.sales-section{
    width: 100%;
    height: 330px;
    // background-color: red;
    padding: 50px 0px 50px 0px;
    @include flexRow(center);
    background-color: darken(white, 3%);
    border-top: 1px solid $light-gray;
    // border-bottom: 1px solid $light-gray;

    &__container{
        max-width: 1360px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexRow(space-between);
    }

    &__content-block{
        width: 310px;
        height: 100%;
        // background-color: pink;
        @include flexColumn(flex-start);
    }

    &__header{
        font-size: 24px;
        font-weight: 400;
        color: $black;
    }

    &__header_accent{
        font-size: 18px;
        color: $accent;
    }

    &__text{
        margin-top: 20px;
        color: $black;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }

    &__sale{
        width: 300px;
        padding: 10px;
        height: calc(100% - 20px);
        // background-color: pink;
        @include flexColumn(flex-start);
        border-radius: 5px;
        color: black;
        text-decoration: none;
    }

    &__img-block{
        width: 100%;
        height: 205px;
        background-color: $accent;
        border-radius: 3px;
        overflow: hidden;
        position: relative;
        @include flexRow(center);
        align-items: center;
        &::before{
            display: block;
            content: "";
            width: 30px;
            height: 300px;
            background-color: rgba(255, 255, 255, 0.301);
            backdrop-filter: blur(2px);
            position: absolute;
            transform: rotate(20deg);
            top: -30%;
            left: -150px;
        }
        &:hover{
            .sales-section__img{
                transform: scale(1.07);
            }
            &::before{
                animation: 1s saleImgAnim normal forwards;
                // animation-play-state: ;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
        }
    }

    @keyframes saleImgAnim {
        0%{left: -150px;}
        50%{left: 360px;}
        100%{left: 360px;}
    }

    &__img{
        transition: 0.3s;
        max-width: 90%;
        max-height: 90%;
        object-fit: cover;
        border-radius: 3px;
    }

    &__name{
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        color: $black;
        margin-top: 10px;
        // background-color: plum;
    }
}

.sale-section{
    width: 100%;
    // height: 330px;
    // background-color: red;
    padding: 50px 0px 50px 0px;
    @include flexRow(center);
    background-color: darken(white, 3%);
    border-top: 2px solid $accent;
    border-bottom: 2px solid $accent;

    &__container{
        max-width: 1360px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__text{
        // background-color: red;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        text-align: center;
        font-size: 24px;
    }

    &__text_accent{
        font-weight: 500;
        color: $accent;
    }

    &__small-text{
        // background-color: red;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        text-align: center;
        font-size: 14px;
        margin-top: 10px;
        color: darken(white, 20%);
    }

    &__form-header{
        // background-color: red;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        text-align: center;
        font-size: 28px;
        color: $accent;
    }

    &__subform{
        width: 100%;
        height: 75px;
        @include flexRow(center);
        align-items: center;
        // background-color: plum;
        margin-top: 15px;
        flex-wrap: wrap;
    }

    &__subform-input{
        width: 210px;
        padding: 0px 5px 0px 5px;
        height: 35px;
        background-color: white;
        border: 1px solid black;
        margin: 0px 10px 0px 10px;
        outline: none;
        border-radius: 5px;
    }

    &__subform-button{
        width: 200px;
        height: 37px;
        background-color: $accent;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        margin: 0px 10px 0px 10px;
    }

    &__subform-error{
        width: 100%;
        height: 20px;
        margin-top: 5px;
        color: $accent;
        text-align: center;
    }
}

.workers-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);
    background-color: white;
    border-bottom: 1px solid $light-gray;

    &__container{
        max-width: 1360px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header-block{
        width: 100%;
        height: 50px;
        // background-color: pink;
        @include flexRow(space-between);
        align-items: center;
    }

    &__header{
        color: $black;
        font-size: 24px;
        font-weight: 400;
    }

    &__all-button{
        cursor: pointer;
        transition: 0.3s;
        font-size: 12px;
        color: $light-gray;
        text-decoration: none;
        &:hover{
            color: $black;
        }
    }

    &__workers-container{
        width: 100%;
        height: 332px;
        // background-color: pink;
        margin-top: 20px;
        @include flexRow(space-between);
    }

    &__worker{
        width: 190px;
        height: 200px;
        padding: 30px;
        // background-color: plum;
        // border: 1px solid lighten($black, 15%);
        border-radius: 3px;
        @include flexColumn(flex-start);
        align-items: center;
        background-color: darken(white, 7%);
    }

    &__img-block{
        width: 150px;
        height: 150px;
        // background-color: orange;
        overflow: hidden;
        position: relative;
        
        // display: none;
        &::after{
            display: block;
            content: "";
            width: 20px;
            height: 200px;
            background-color: rgba(255, 255, 255, 0.301);
            backdrop-filter: blur(2px);
            position: absolute;
            transform: rotate(20deg);
            top: -15%;
            left: -55px;
        }
        &:hover{
            &::after{
                animation: 1s workerImgAnim normal forwards;
                // animation-play-state: ;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
        }
    }

    @keyframes workerImgAnim {
        0%{left: -55px;}
        50%{left: 185px;}
        100%{left: 185px;}
    }

    &__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
        // display: none;
    }

    &__name{
        width: 100%;
        // background-color: orange;
        text-align: center;
        margin-top: 10px;
        font-size: 18px;
        color: $black;
        font-weight: 300;
    }

    &__exp{
        // background-color: orange;
        width: 100%;
        @include flexRow(center);
        margin-top: 5px;
        font-size: 16px;
        color: $black;
        font-weight: 400;
    }

    &__exp-accent{
        color: $accent;
        margin-left: 5px;
    }
}

.reviews-section{
    width: 100%;
    // height: 500px;
    // background-color: red;
    padding: 50px 0px 50px 0px;
    @include flexRow(center);
    background-color: white;

    &__container{
        max-width: 1360px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header-block{
        width: 100%;
        height: 50px;
        // background-color: pink;
        @include flexRow(space-between);
        align-items: center;
    }

    &__header{
        color: $black;
        font-size: 24px;
        font-weight: 400;
    }

    &__buttons-block{
        // background-color: plum;
        @include flexRow(flex-start);
        align-items: center;
        height: 100%;
    }

    &__write-button{
        cursor: pointer;
        transition: 0.3s;
        font-size: 12px;
        color: $accent;
        text-decoration: none;
        margin-right: 30px;
        &:hover{
            color: $accent;
        }
    }

    &__all-button{
        cursor: pointer;
        transition: 0.3s;
        font-size: 12px;
        color: $accent;
        text-decoration: none;
        &:hover{
            color: $accent;
        }
    }

    &__content-container{
        width: 100%;
        height: 210px;
        // background-color: bisque;
        @include flexRow(space-between);
        align-items: center;
        margin-top: 20px;
    }

    &__slider{
        max-width: 1260px;
        width: 100%;
        height: 100%;
        // background-color: pink;
        @include flexRow(space-between);
        align-items: center;
        // margin-top: 10px;
    }



    &__control-button{
        width: 40px;
        height: 40px;
        // background-color: lighten($black, 20%);
        @include flexRow(center);
        align-items: center;
        border-radius: 3px;
        transition: 0.3s;
        cursor: pointer;
        svg{
            width: 20px;
            height: 20px;
            path{
                fill: $light-gray;
                transition: 0.3s;
            }
        }
        &:hover{
            background-color: $accent;
            svg{
                path{
                    fill: white;
                }
            }
        }
    }

    &__slide{
        width: 100%;
        height: 100%;
        // background-color: plum;
        // @include flexRow(space-between);
        @include flexColumn(flex-start);
    }

    &__info-block{
        // background-color: orange;
        width: calc(100% - 200px);
        padding: 0px 100px 0px 100px;
        height: 70px;
        @include flexRow(space-between);
    }

    &__author-block{
        height: 100%;
        // background-color: aqua;
        @include flexColumn(center);
    }

    &__date{
        font-size: 10px;
        font-weight: 400;
        color: $light-gray;
    }

    &__author{
        font-size: 20px;
        font-weight: 400;
        color: $black;
        margin-top: 10px;
    }

    &__rating-block{
        width: 175px;
        height: 100%;
        // background-color: aqua;
        @include flexRow(center);
        align-items: center;
    }

    &__star{
        margin-left: 5px;
    }

    &__star_active{
        path{
            fill: $accent;
        }
    }

    &__text{
        width: calc(100% - 200px);
        padding: 0px 100px 0px 100px;
        // background-color: orange;
        height: 75px;
        overflow: hidden;
        margin-top: 5px;
        position: relative;
        font-size: 16px;
        color: $black;
        line-height: 25px;

        &::before{
            width: 50px;
            height: 50px;
            // background-color: red;
            display: block;
            content: "";
            position: absolute;
            left: 30px;
            background-image: url(./../src/img/review.svg);
            background-repeat: no-repeat;
            // @include flexRow(center);
            background-position-x: center;
        }
    }

    &__show-button{
        width: 150px;
        height: 30px;
        font-size: 14px;
        // background-color: orange;
        margin-top: 20px;
        text-align: center;
        @include flexColumn(center);
        cursor: pointer;
        margin-left: 100px;
        border-radius: 3px;
        border: 2px solid #414141;
        color: #414141;
        transition: 0.3s;
        &:hover{
            border-color: white;
            color: white;
        }
    }
}

.advices-section{
    width: 100%;
    // height: 180px;
    padding: 60px 0px 60px 0px;
    background-color: white;
    // background-color: red;
    border-top: 1px solid $light-gray;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header-block{
        width: 100%;
        height: 50px;
        // background-color: pink;
        @include flexRow(space-between);
        align-items: center;
    }

    &__header{
        color: $black;
        font-size: 24px;
        font-weight: 400;
    }

    &__buttons-block{
        // background-color: plum;
        @include flexRow(flex-start);
        align-items: center;
        height: 100%;
    }

    &__write-button{
        cursor: pointer;
        transition: 0.3s;
        font-size: 12px;
        color: $accent;
        text-decoration: none;
        margin-right: 30px;
        &:hover{
            color: $accent;
        }
    }

    &__all-button{
        cursor: pointer;
        transition: 0.3s;
        font-size: 12px;
        color: $accent;
        text-decoration: none;
        &:hover{
            color: $accent;
        }
    }

    &__content-container{
        width: 100%;
        // height: 210px;
        // background-color: bisque;
        @include flexRow(center);
        flex-wrap: wrap;
        align-items: center;
        margin-top: 20px;
    }

    &__advice{
        margin: 10px 0px 10px 0px;
        // max-width: 400px;
        width: calc(100% - 40px);
        padding: 20px;
        // height: 315px;
        // background-color: plum;
        background-color: darken(white, 5%);
        // border-radius: 315px;
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        text-decoration: none;
        transition: 0.2s;
        @include flexRow(center);
        align-items: center;
        &:hover{
            // top: -3px;
            box-shadow: 0px 0px 10px lightgray;
        }
        &:nth-child(even){
            // background-color: red;
            .advices-section__img-block{
                order: 2;
                border-left: 3px solid $accent;
                border-right: none;
                @include flexRow(center);
                align-items: center;
                padding: 0px 0px 0px 10px;  
            }

            .advices-section__texts-block{
                margin-right: 20px;
                margin-left: 0px;
            }
        }
    }

    &__content-block{
        width: 100%;
        height: 100%;
        // background-color: rgba(0, 0, 0, 0.404);
        // position: absolute;
        // background-color: red;
        text-align: center;
        overflow: hidden;
        @include flexRow(flex-start);
        align-items: center;
        // z-index: 2;
    }

    &__img-block{
        width: 200px;
        max-height: 190px;
        height: 100%;
        // background-color: yellow;
        border-right: 3px solid $accent;
        @include flexRow(center);
        align-items: center;
        padding: 0px 10px 0px 0px;
    }

    &__img{
        max-width: 80%;
        max-height: 80%;
    }
    

    &__texts-block{
        // width: 100%;
        width: calc(100% - 235px);
        margin-left: 20px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__advice-number{
        // background-color: pink;
        width: 100%;
        font-size: 20px;
        color: black;
        text-align: start;
        height: 25px;
    }

    &__advice-header{
        color: $accent;
    }

    &__advice-text{
        // background-color: pink;
        // color: white;
        font-weight: 400;
        font-size: 14px;
        margin-top: 15px;
        width: calc(100% - 20px);
        margin-left: 20px;
        // margin-top: 20px;
        // background-color: pink;
        color: black;
        max-height: 150px;
        height: calc(100% - 40px);
        overflow: hidden;
        letter-spacing: 0.7px;
        line-height: 22px;
    }
}

.section-header{
    // background-color: pink;
    font-size: 32px;
    font-weight: 400;
}

.bread-block{
    margin-top: 15px;
    // background-color: pink;
    @include flexRow(flex-start);
    list-style: none;

    &__item{
        // background-color: plum;
        margin-right: 10px;
        position: relative;
        @include flexRow(flex-start);
        align-items: center;

        &::after{
            margin-left: 10px;
            display: block;
            content: "";
            width: 15px;
            height: 1px;
            background-color: black;
        }
        &:last-child{
            .bread-block__link{
                cursor: default;
                &:hover{
                    text-decoration: none;
                    transition: 0.3s;
                    color: black;
                }
            }
            &::after{
                display: none;
            }
        }
    }

    &__link{
        text-decoration: none;
        // background-color: orange;
        cursor: pointer;
        transition: 0.3s;
        color: black;
        &:hover{
            color: red;
        }
    }

    &__name{
        font-size: 14px;
        font-weight: 400;
    }
}

.small-section-text{
    width: 100%;
    // background-color: pink;
    margin-top: 40px;
    color: black;
    font-weight: 400;
    font-size: 16px;
}

.sort-block{
    width: calc(100% - 40px);
    min-height: 40px;
    padding: 10px 20px 10px 20px;
    background-color: $accent;
    border-radius: 10px;
    margin-top: 20px;
    @include flexRow(space-around);
    flex-wrap: wrap;
    align-items: center;
    z-index: 10;

    &__text{
        text-align: center;
        // margin: 10px;
        width: 300px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(center);
        color: white;
        font-size: 18px;
    }
    
    &__sort-container{
        margin: 10px;
        // background-color: yellow;
        // margin-left: 15px;
        position: relative;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            // background-color: darken(white, 5%);
        }
    }
    
    &__first-item{
        // background-color: red;
        border-radius: 10px;
        background-color: white;
        width: 280px;
        padding: 0px 10px 0px 10px;
        height: 40px;
        @include flexColumn(center);
        text-align: center;
    }

    &__first-item_active{
        border-radius: 5px 5px 0px 0px;
    }
    
    &__first-item_error{
        box-shadow: 0px 0px 7px 0px $black;
        color: red;
        // border-radius: 5px 5px 0px 0px;
    }
    
    &__tags-list{
        position: absolute;
        width: 300px;
        height: 300px;
        list-style: none;
        background-color: white;
        // background-color: red;
        top: 100%;
        left: 0px;
        @include flexColumn(flex-start);
        display: none;
        overflow-y: scroll;
        
        &::-webkit-scrollbar{
            width: 2px;
        }
        
        &::-webkit-scrollbar-thumb{
            background-color: $black;
        }
    }
    
    &__tag{
        // background-color: pink;
        // margin-top: 5px;
        padding: 5px 10px 5px 10px;
        // padding: 5px 0px 5px 0px;
        // margin-bottom: 5px;
        font-size: 12px;
        transition: 0.3s;
        &:hover{
            background-color: darken(white, 5%);
        }
    }
    
    &__button{
        margin: 10px;
        width: 200px;
        height: 40px;
        background-color: white;
        // margin-left: 15px;
        border-radius: 10px;
        text-decoration: none;
        color: $black;
        @include flexColumn(center);
        text-align: center;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: darken(white, 10%);
        }
    }
}

.articles-section{
    width: 100%;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__articles-container{
        width: 100%;
        // height: 430px;
        // background-color: pink;
        margin-top: 40px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__article{
        margin: 12px;
        width: 300px;
        height: 300px;
        border-radius: 315px;
        // background-color: plum;
        position: relative;
        margin: 12px;
        // border-radius: 3px;
        overflow: hidden;
        text-decoration: none;
        background-color: $accent;
        transition: 0.2s;
        @include flexRow(center);
        align-items: center;
        // box-shadow: 0px 0px 10px darken($accent, 5%);
        &:hover{
            box-shadow: 0px 0px 15px darken($accent, 5%);
            // top: -3px;
        }
    }


    &__content-block{
        // width: calc(100% - 250px);
        // height: calc(100% - 60px);
        width: 275px;
        height: 275px;
        border-radius: 275px;
        // padding: 30px;
        // background-color: rgb(255, 166, 0);
        // background-color: rgba(0, 0, 0, 0.308);
        position: absolute;
        @include flexColumn(center);
        // background-color: pink;
        background-color: darken($accent, 5%);
        box-shadow: 0px 0px 10px darken($accent, 5%);
        overflow: hidden;
        // z-index: 2;
    }

    &__stat-block{
        width: 100%;
        height: 20px;
        // background-color: white;
        border-radius: 5px;
        @include flexRow(center);
        // background-color: plum;
        margin-top: 10px;
    }

    &__time-block{
        width: 80px;
        margin: 0px 5px 0px 5px;
        height: 20px;
        background-color: white;
        @include flexRow(center);
        align-items: center;
        border-radius: 5px;
        color: black;
    }

    &__stats-text{
        margin-left: 5px;
    }

    &__article-header{
        color: white;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        // background-color: plum;
        height: 100px;
        @include flexColumn(center);
        align-items: center;
        width: calc(100% - 20px);
        font-weight: 500;
        letter-spacing: 0.7px;
        padding: 0px 10px 0px 10px;


    }
}

.article-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        // height: 1000px;
        // background-color: yellow;
        @include flexColumn(flex-start);
        // border-radius: 5px;
    }

    &__header-block{
        width: 100%;
        height: 400px;
        // background-color: pink;
        border-radius: 10px;
        @include flexRow(space-between);
        margin-bottom: 15px;
    }

    &__stat-block{
        padding: 10px;
        width: 300px;
        height: 380px;
        // background-color: plum;
        @include flexColumn(space-between);
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
    }

    &__img-block{
        width: 300px;
        height: 300px;
        // background-color: orange;
        @include flexColumn(center);
        align-items: center;
    }

    &__img{
        max-width: 100%;
        max-height: 100%;
    }

    &__stat-container{
        width: 320px;
        height: 70px;
        // background-color: orange;
        @include flexColumn(flex-start);
    }

    &__stat-item{
        margin-bottom: 10px;
    }

    &__header{
        width: calc(100% - 400px);
        height: 100%;
        // background-color: plum;
        border-radius: 10px;
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
        font-size: 46px;
        font-weight: 400;
    }

    &__article{
        // background-color: pink;
        margin-top: 30px;
        // padding: 10px;
        width: calc(100% - 20px);
        border-radius: 10px;
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
    }
}

.big-advices-section{
    width: 100%;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__advices-container{
        width: 100%;
        // height: 430px;
        // background-color: pink;
        margin-top: 40px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__advice{
        margin: 10px 0px 10px 0px;
        // max-width: 400px;
        width: calc(100% - 40px);
        padding: 20px;
        // height: 315px;
        // background-color: plum;
        background-color: darken(white, 5%);
        // border-radius: 315px;
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        text-decoration: none;
        transition: 0.2s;
        @include flexRow(center);
        align-items: center;
        &:hover{
            // top: -3px;
            box-shadow: 0px 0px 10px lightgray;
        }
        &:nth-child(even){
            // background-color: red;
            .big-advices-section__img-block{
                order: 2;
                border-left: 3px solid $accent;
                border-right: none;
                @include flexRow(center);
                align-items: center;
                padding: 0px 0px 0px 10px;  
            }

            .big-advices-section__texts-block{
                margin-right: 20px;
                margin-left: 0px;
            }
        }
    }

    &__content-block{
        width: 100%;
        height: 100%;
        // background-color: rgba(0, 0, 0, 0.404);
        // position: absolute;
        // background-color: red;
        text-align: center;
        overflow: hidden;
        @include flexRow(flex-start);
        align-items: center;
        // z-index: 2;
    }

    &__img-block{
        width: 200px;
        max-height: 190px;
        height: 100%;
        // background-color: yellow;
        border-right: 3px solid $accent;
        @include flexRow(center);
        align-items: center;
        padding: 0px 10px 0px 0px;
    }

    &__img{
        max-width: 100%;
        max-height: 100%;
    }
    

    &__texts-block{
        // width: 100%;
        width: calc(100% - 235px);
        margin-left: 20px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__advice-number{
        // background-color: pink;
        width: 100%;
        font-size: 20px;
        color: black;
        text-align: start;
        height: 25px;
    }

    &__advice-header{
        color: $accent;
    }

    &__advice-text{
        // background-color: pink;
        // color: white;
        font-weight: 400;
        font-size: 14px;
        margin-top: 15px;
        width: calc(100% - 20px);
        margin-left: 20px;
        // margin-top: 20px;
        // background-color: pink;
        color: black;
        max-height: 150px;
        height: calc(100% - 40px);
        overflow: hidden;
        letter-spacing: 0.7px;
        line-height: 22px;
    }

    
}

.advice-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        // height: 1000px;
        // background-color: yellow;
        @include flexColumn(flex-start);
        // border-radius: 5px;
    }

    &__header-block{
        width: 100%;
        height: 400px;
        // background-color: pink;
        border-radius: 10px;
        @include flexRow(space-between);
        margin-bottom: 15px;
    }

    &__stat-block{
        padding: 10px;
        width: 300px;
        height: 380px;
        // background-color: plum;
        @include flexColumn(center);
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
    }

    &__img-block{
        width: 300px;
        height: 300px;
        // background-color: orange;
        @include flexColumn(center);
        align-items: center;
    }

    &__img{
        max-width: 100%;
        max-height: 100%;
    }

    &__stat-container{
        width: 320px;
        height: 70px;
        // background-color: orange;
        @include flexColumn(flex-start);
    }

    &__stat-item{
        margin-bottom: 10px;
    }

    &__header-container{
        width: calc(100% - 440px);
        height: calc(100% - 40px);
        padding: 20px;
        // background-color: plum;
        border-radius: 10px;
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
    }

    &__preheader-text{
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 15px;
    }

    &__header{
        // width: calc(100% - 20px);
        width: 100%;
        // height: 100%;
        // background-color: plum;
        @include flexColumn(center);
        font-size: 46px;
        font-weight: 300;
    }

    &__advice{
        // background-color: pink;
        margin-top: 30px;
        padding: 20px;
        width: calc(100% - 40px);
        border-radius: 10px;
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        line-height: 36px;
        font-size: 20px;
        font-weight: 300;
    }
}

.big-questions-section{
    width: 100%;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);
    
    &__container{
        max-width: 1360px;
        width: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__counter{
        // background-color: pink;
        margin-top: 20px;
        font-size: 24px;
    }
    
    &__counter-value{
        color: $accent;
        font-weight: 600;
    }
    
    &__questions-container{
        width: calc(100% - 2px);
        // height: 430px;
        // background-color: pink;
        margin-top: 40px;
        @include flexRow(center);
        align-items: flex-start;
        flex-wrap: wrap;
    }
    
    &__questions-block{
        // border-radius: 5px;
        // border: 1px solid lightgrey;
        // background-color: darken(white, 3%);
        // background-color: plum;
        width: calc(100% - 330px);
        @include flexColumn(flex-start);
        // align-items: center;
        // height: auto;
        // min-height: 300px;
    }

    &__question{
        width: calc(100% - 30px);
        // height: 470px;
        // background-color: orange;
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        border-radius: 5px;
        padding: 15px;
        @include flexColumn(flex-start);
        text-decoration: none;
        &:not(:last-child){
            // border-bottom: 1px solid lightgrey;
        }
        &:not(:first-child){
            margin-top: 20px;
        }
    }

    &__question-number{
        // background-color: orange;
        font-size: 18px;
        font-weight: 600;
        color: $black;
    }

    &__author-block{
        // background-color: orange;
        margin-top: 10px;
        font-size: 14px;
        @include flexRow(flex-start);
        // color: yellowgreen;
    }

    &__author-name{
        color: $accent;
        margin-right: 10px;
    }

    &__date{
        color: $accent;
    }

    &__question-header{
        // background-color: orange;
        margin-top: 20px;
        font-size: 18px;
        font-weight: 600;
        color: black;
    }

    &__question-text{
        width: calc(100% - 20px);
        margin-left: 20px;
        // background-color: orange;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        color: black;
    }

    &__answer-header{
        // background-color: orange;
        width: calc(100% - 20px);
        margin-left: 20px;
        margin-top: 20px;
        font-size: 18px;
        font-weight: 600;
        color: black;
    }

    &__answer-text{
        width: calc(100% - 40px);
        margin-left: 40px;
        // background-color: orange;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        color: black;
    }

    &__form-block{
        width: 300px;
        // height: 400px;
        // background-color: plum;
        // background-color: darken(white, 3%);
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        // border: 1px solid lightgrey;
        margin-left: 20px;
        border-radius: 5px;
        overflow: hidden;
    }

    &__form{
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        padding: 15px;
        // background-color: orange;
        @include flexColumn(flex-start);
    }

    &__form-header{
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        // background-color: aqua;
        margin-bottom: 30px;
    }

    &__input{
        width: calc(100% - 12px);
        height: 40px;
        // background-color: aqua;
        margin-top: 10px;
        outline: none;
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding: 0px 0px 0px 10px;
    }

    &__textarea{
        width: calc(100% - 22px);
        height: 140px;
        // background-color: aqua;
        margin-top: 10px;
        outline: none;
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding: 10px;
        resize: none;
    }

    &__form-error{
        width: 100%;
        margin-top: 5px;
        color: $accent;
        text-align: center;
    }

    &__form-button{
        width: 100%;
        height: 40px;
        margin-top: 15px;
        background-color: black;
        border-radius: 5px;
        text-decoration: none;
        color: white;
        text-align: center;
        @include flexColumn(center);
        user-select: none;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: lighten(black, 20%);
        }
        &:active{
            transform: scale(0.9);
        }
    }
}

.question-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        // height: 1000px;
        // background-color: yellow;
        @include flexColumn(flex-start);
        // border-radius: 5px;
    }

    &__reason-block{
        width: calc(100% - 40px);
        padding: 20px;
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        border-radius: 10px;
        @include flexColumn(center);
        margin-bottom: 15px;
    }

    &__reason-header{
        font-size: 20px;
    }

    &__reason{
        // background-color: pink;
        width: calc(100% - 20px);
        margin-left: 20px;
        margin-top: 10px;
        font-size: 36px;
    }

    &__question-block{
        margin-top: 30px;
        width: calc(100% - 40px);
        padding: 20px;
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        border-radius: 10px;
        @include flexColumn(center);
    }

    &__question-header{
        font-size: 20px;
        font-weight: 600;
    }

    &__question{
        // background-color: pink;
        width: calc(100% - 20px);
        margin-left: 20px;
        margin-top: 10px;
        font-size: 24px;
        font-weight: 400;
    }

    &__answer-block{
        margin-top: 30px;
        width: calc(100% - 40px);
        padding: 20px;
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        border-radius: 10px;
        @include flexColumn(center);
    }

    &__answer-header{
        font-size: 20px;
        font-weight: 600;
    }

    &__answer{
        // background-color: pink;
        width: calc(100% - 20px);
        margin-left: 20px;
        margin-top: 10px;
        font-size: 24px;
        font-weight: 400;
    }
}

.cat-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        // height: 1000px;
        // background-color: yellow;
        @include flexColumn(flex-start);
        // border-radius: 5px;
    }

    &__header-block{
        width: 100%;
        height: 400px;
        // background-color: pink;
        border-radius: 10px;
        @include flexRow(space-between);
        margin-bottom: 15px;
    }

    &__img-block{
        padding: 10px;
        width: 300px;
        height: 380px;
        // background-color: plum;
        @include flexColumn(center);
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
    }

    &__img{
        max-width: 100%;
        max-height: 100%;
    }

    &__content-block{
        width: calc(100% - 400px);
        height: 100%;
        // background-color: plum;
        border-radius: 10px;
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        // text-align: center;
    }

    &__header{
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        // height: 100%;
        // background-color: plum;
        // border-radius: 10px;
        // background-color: darken(white, 3%);
        // box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
        font-size: 36px;
        font-weight: 400;
    }

    &__subheader{
        // width: 100%;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        // height: 100%;
        // background-color: plum;
        // border-radius: 10px;
        // background-color: darken(white, 3%);
        // box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        margin-top: 20px;
    }
    
    &__form-header{
        text-decoration: underline;
        width: 100%;
        // height: 100%;
        // background-color: plum;
        // border-radius: 10px;
        // background-color: darken(white, 3%);
        // box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        margin-top: 20px;
    }

    &__form{
        width: 100%;
        height: 75px;
        @include flexRow(center);
        align-items: center;
        // background-color: plum;
        margin-top: 15px;
        flex-wrap: wrap;
    }

    &__form-input{
        width: 210px;
        padding: 0px 5px 0px 5px;
        height: 35px;
        background-color: white;
        border: 1px solid black;
        margin: 0px 10px 0px 10px;
        outline: none;
        border-radius: 5px;
    }

    &__form-button{
        width: 200px;
        height: 37px;
        background-color: $accent;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: white;
    }

    &__form-error{
        width: 100%;
        height: 20px;
        // background-color: pink;
        text-align: center;
        margin-top: 10px;
        color: $accent;
    }

    &__article{
        // background-color: pink;
        margin-top: 30px;
        // padding: 10px;
        width: calc(100% - 20px);
        border-radius: 10px;
        box-shadow: 0px 0px 4px lightgray;
        background-color: darken(white, 3%);
    }
    
    &__errors-block{
        width: 100%;
        // height: 500px;
        // background-color: red;
        margin-top: 30px;
        @include flexColumn(flex-start);
    }
    
    &__error-header-block{
        width: calc(100% - 20px);
        min-height: 50px;
        padding: 0px 10px 0px 10px;
        background-color: $accent;
        @include flexRow(space-between);
        box-shadow: 0px 0px 4px lightgray;
        // background-color: darken(white, 3%);
        border-radius: 5px;
        align-items: center;
        // overflow: hidden;
    }

    &__error-header-block_active{
        border-radius: 5px;
    }
    
    &__error-header{
        // background-color: pink;
        font-size: 36px;
        color: white;
    }

    &__header-button{
        width: 35px;
        height: 35px;
        // background-color: lighten($accent, 10%);
        background-color: white;
        @include flexRow(center);
        align-items: center;
        border-radius: 40px;
        cursor: pointer;
        transition: 0.3s;
        svg{
            width: 20px;
            height: 20px;
            rect{
                transition: 0.3s;
            }
        }
        &:hover{
            svg{
                rect{
                    fill: $accent;
                }
            }
        }
    }

    &__header-button_active{
        svg{
            transform: rotate(45deg);
        }
    }

    &__errors-list{
        width: 100%;
        // background-color: red;
        @include flexColumn(flex-start);
        align-items: center;
        margin-top: 10px;
        height: 0px;
        overflow: hidden;
    }
    
    &__line{
        margin-bottom: 10px;
        width: calc(100% - 40px);
        padding: 20px 10px 20px 10px;
        @include flexRow(space-between);
        text-decoration: none;
        color: white;
        background-color: lighten($accent, 5%);
        border-radius: 5px;
        font-size: 18px;
        transition: 0.3s;
        &:not(:first-child){
            cursor: pointer;
            transition: 0.3s;
            &:hover{
                background-color: lighten($accent, 10%);
                box-shadow: 0px 0px 4px lightgray;
            }
        }
    }

    &__name{
        padding: 10px;
        @include flexColumn(center);
        color: white;
        width: 65%;
    }

    &__price{
        text-align: center;
        font-size: 15px;
        width: calc(10% - 20px);
        background-color: darken($accent, 5%);
        padding: 10px;
        border-radius: 5px;
        @include flexColumn(center);
    }
    
    &__time{
        @include flexColumn(center);
        font-size: 15px;
        text-align: center;
        width: calc(10% - 20px);
        background-color: darken($accent, 5%);
        padding: 10px;
        border-radius: 5px;
    }
    
    &__warranty{
        @include flexColumn(center);
        font-size: 15px;
        text-align: center;
        width: calc(10% - 20px);
        background-color: darken($accent, 5%);
        padding: 10px;
        border-radius: 5px;
    }

    &__brands-block{
        width: 100%;
        // height: 500px;
        // background-color: red;
        margin-top: 100px;
        @include flexColumn(flex-start);
    }

    &__brands-header{
        width: 100%;
        // height: 50px;
        // background-color: yellow;
        font-size: 36px;
    }

    &__brands-container{
        width: 100%;
        // height: 500px;
        // background-color: yellow;
        margin-top: 20px;
        height: 250px;
        overflow: hidden;
        @include flexRow(center);
        align-items: flex-start;
        flex-wrap: wrap;
    }

    &__brand{
        width: 200px;
        min-height: 80px;
        // background-color: pink;
        box-shadow: 0px 0px 4px lightgray;
        background-color: darken(white, 3%);
        padding: 10px;
        border-radius: 5px;
        text-decoration: none;
        color: black;
        @include flexColumn(center);
        text-align: center;
        font-size: 22px;
        margin: 15px;
        transition: 0.3s;
        &:hover{
            background-color: $accent;
            color: white;
        }
    }

    &__button-block{
        width: 100%;
        height: 50px;
        margin-top: 20px;
        // background-color: pink;
        @include flexRow(center);
        align-items: center;
    }

    &__show-button{
        width: 250px;
        height: 40px;
        background-color: $accent;
        border-radius: 5px;
        box-shadow: 0px 0px 4px lightgray;
        text-align: center;
        color: white;
        @include flexColumn(center);
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: lighten($accent, 10%);
            // background-color: darken(white, 15%);
        }
    }

    &__form-block{
        // width: calc(100% - 20px);
        width: 100%;
        // padding: 10px;
        border-radius: 5px;
        // height: auto;
        // background-color: red;
        position: relative;
        overflow: hidden;
        margin-top: 100px;
        box-shadow: 0px 0px 4px lightgray;
        background-color: darken(white, 3%);
        @include flexColumn(flex-start);
        z-index: 1;
        &::before{
            position: absolute;
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            // background-color: black;
            background-image: url(./../src/img/repair-1.jpg);
            background-attachment: fixed;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: 0%;
            left: 0px;
            top: 0px;
            // right: 0px;
        }
    }
    
    &__subform-container{
        // position: absolute;
        z-index: 1;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        padding: 10px;
        @include flexColumn(flex-start);
        background-color: rgba(0, 0, 0, 0.459);
        left: 0px;
        top: 0px;
    }

    &__subform-header{
        text-align: center;
        width: 100%;
        // background-color: yellow;
        font-size: 36px;
        color: white;
    }

    &__subform-text{
        text-align: center;
        width: 100%;
        // background-color: yellow;
        font-size: 24px;
        margin-top: 10px;
        color: white;
    }

    &__subform{
        width: 100%;
        height: 75px;
        @include flexRow(center);
        align-items: center;
        // background-color: plum;
        margin-top: 15px;
        flex-wrap: wrap;
    }

    &__subform-input{
        width: 210px;
        padding: 0px 5px 0px 5px;
        height: 35px;
        background-color: white;
        border: 1px solid black;
        margin: 0px 10px 0px 10px;
        outline: none;
        border-radius: 5px;
    }

    &__subform-button{
        width: 200px;
        height: 37px;
        background-color: $accent;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        margin: 0px 10px 0px 10px;
    }

    &__subform-error{
        width: 100%;
        height: 20px;
        margin-top: 5px;
        color: white;
        text-align: center;
    }

    &__articles-block{
        width: 100%;
        // height: 500px;
        margin-top: 100px;
        // background-color: red;
        @include flexColumn(flex-start);
    }

    &__articles-header{
        width: 100%;
        // background-color: yellow;
        font-size: 36px;
    }

    &__articles-container{
        @include flexRow(center);
        flex-wrap: wrap;
        // background-color: yellow;
        margin-top: 20px;
        align-items: flex-start;
    }

    &__article{
        margin: 12px;
        width: 300px;
        height: 300px;
        border-radius: 315px;
        // background-color: plum;
        position: relative;
        margin: 12px;
        // border-radius: 3px;
        overflow: hidden;
        text-decoration: none;
        background-color: $accent;
        transition: 0.2s;
        @include flexRow(center);
        align-items: center;
        // box-shadow: 0px 0px 10px darken($accent, 5%);
        &:hover{
            box-shadow: 0px 0px 15px darken($accent, 5%);
            // top: -3px;
        }
    }


    &__article-content-block{
        // width: calc(100% - 250px);
        // height: calc(100% - 60px);
        width: 275px;
        height: 275px;
        border-radius: 275px;
        // padding: 30px;
        // background-color: rgb(255, 166, 0);
        // background-color: rgba(0, 0, 0, 0.308);
        position: absolute;
        @include flexColumn(center);
        // background-color: pink;
        background-color: darken($accent, 5%);
        box-shadow: 0px 0px 10px darken($accent, 5%);
        overflow: hidden;
        // z-index: 2;
    }

    &__stat-block{
        width: 100%;
        height: 20px;
        // background-color: white;
        border-radius: 5px;
        @include flexRow(center);
        // background-color: plum;
        margin-top: 10px;
    }

    &__time-block{
        width: 80px;
        margin: 0px 5px 0px 5px;
        height: 20px;
        background-color: white;
        @include flexRow(center);
        align-items: center;
        border-radius: 5px;
        color: black;
    }

    &__stats-text{
        margin-left: 5px;
    }

    &__article-header{
        color: white;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        // background-color: plum;
        height: 100px;
        @include flexColumn(center);
        align-items: center;
        width: calc(100% - 20px);
        font-weight: 500;
        letter-spacing: 0.7px;
        padding: 0px 10px 0px 10px;


    }

    &__advices-block{
        width: 100%;
        // height: 500px;
        margin-top: 100px;
        // background-color: red;
        @include flexColumn(flex-start);
        // position: relative;
    }

    &__advices-header{
        width: 100%;
        // background-color: yellow;
        font-size: 36px;
    }

    &__advices-container{
        @include flexRow(center);
        flex-wrap: wrap;
        // background-color: yellow;
        margin-top: 20px;
        align-items: flex-start;
    }

    &__advice{
        margin: 10px 0px 10px 0px;
        // max-width: 400px;
        width: calc(100% - 40px);
        padding: 20px;
        // height: 315px;
        // background-color: plum;
        background-color: darken(white, 5%);
        // border-radius: 315px;
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        text-decoration: none;
        transition: 0.2s;
        @include flexRow(center);
        align-items: center;
        &:hover{
            // top: -3px;
            box-shadow: 0px 0px 10px lightgray;
        }
        &:nth-child(even){
            // background-color: red;
            .cat-section__advice-img-block{
                order: 2;
                border-left: 3px solid $accent;
                border-right: none;
                @include flexRow(center);
                align-items: center;
                padding: 0px 0px 0px 10px;  
            }

            .cat-section__texts-block{
                margin-right: 20px;
                margin-left: 0px;
            }
        }
    }

    &__advice-content-block{
        width: 100%;
        height: 100%;
        // background-color: rgba(0, 0, 0, 0.404);
        // position: absolute;
        // background-color: red;
        text-align: center;
        overflow: hidden;
        @include flexRow(flex-start);
        align-items: center;
        // z-index: 2;
    }

    &__advice-img-block{
        width: 200px;
        max-height: 190px;
        height: 100%;
        // background-color: yellow;
        border-right: 3px solid $accent;
        @include flexRow(center);
        align-items: center;
        padding: 0px 10px 0px 0px;
    }

    &__advice-img{
        max-width: 80%;
        max-height: 80%;
    }
    

    &__texts-block{
        // width: 100%;
        width: calc(100% - 235px);
        margin-left: 20px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__advice-number{
        // background-color: pink;
        width: 100%;
        font-size: 20px;
        color: black;
        text-align: start;
        height: 25px;
    }

    &__advice-header{
        color: $accent;
    }

    &__advice-text{
        // background-color: pink;
        // color: white;
        font-weight: 400;
        font-size: 14px;
        margin-top: 15px;
        width: calc(100% - 20px);
        margin-left: 20px;
        // margin-top: 20px;
        // background-color: pink;
        color: black;
        max-height: 150px;
        height: calc(100% - 40px);
        overflow: hidden;
        letter-spacing: 0.7px;
        line-height: 22px;
    }
}

.brand-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        // height: 1000px;
        // background-color: yellow;
        @include flexColumn(flex-start);
        // border-radius: 5px;
    }

    &__header-block{
        width: 100%;
        height: 400px;
        // background-color: pink;
        border-radius: 10px;
        @include flexRow(space-between);
        margin-bottom: 15px;
    }

    &__img-block{
        padding: 10px;
        width: 300px;
        height: 380px;
        // background-color: plum;
        @include flexColumn(center);
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
    }

    &__img{
        max-width: 100%;
        max-height: 100%;
    }

    &__content-block{
        width: calc(100% - 400px);
        height: 100%;
        // background-color: plum;
        border-radius: 10px;
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        // text-align: center;
    }

    &__header{
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        // height: 100%;
        // background-color: plum;
        // border-radius: 10px;
        // background-color: darken(white, 3%);
        // box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
        font-size: 36px;
        font-weight: 400;
    }

    &__subheader{
        // width: 100%;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        // height: 100%;
        // background-color: plum;
        // border-radius: 10px;
        // background-color: darken(white, 3%);
        // box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        margin-top: 20px;
    }
    
    &__form-header{
        text-decoration: underline;
        width: 100%;
        // height: 100%;
        // background-color: plum;
        // border-radius: 10px;
        // background-color: darken(white, 3%);
        // box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        margin-top: 20px;
    }

    &__form{
        width: 100%;
        height: 75px;
        @include flexRow(center);
        align-items: center;
        // background-color: plum;
        margin-top: 15px;
        flex-wrap: wrap;
    }

    &__form-input{
        width: 210px;
        padding: 0px 5px 0px 5px;
        height: 35px;
        background-color: white;
        border: 1px solid black;
        margin: 0px 10px 0px 10px;
        outline: none;
        border-radius: 5px;
    }

    &__form-button{
        width: 200px;
        height: 37px;
        background-color: $accent;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: white;
    }

    &__form-error{
        width: 100%;
        height: 20px;
        margin-top: 5px;
        text-align: center;
        color: $accent;
    }

    
    &__cats-container{
        width: 100%;
        // height: 790px;
        // overflow: hidden;
        // background-color: pink;
        list-style: none;
        @include flexRow(center);
        flex-wrap: wrap;
        margin-top: 50px;
    }

    &__cat{
        // background-color: plum;
        background-color: white;
        background-color: darken(white, 3%);
        margin: 15px;
        border-radius: 3px;
        // @include flexRow(space-between);
        transition: 0.3s;
        &:hover{
            // background-color: darken(white, 10%);
            box-shadow: 0px 0px 10px darken(white, 16%);
            .cats-section__img{
                transform: scale(1.1);
            }
        }
    }
    
    &__cat-link{
        width: 600px;
        height: 310px;
        padding: 25px;
        @include flexRow(space-between);
        // width: 100%;
        // height: 100%;
        // background-color: orange;
        text-decoration: none;
    }

    &__cat-content-block{
        width: 320px;
        height: 100%;
        // background-color: aqua;
        @include flexColumn(center);
        align-items: center;
    }
    
    &__name{
        // margin-top: 100px;
        color: $black;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        width: calc(100% - 20px);
        // background-color: red;
    }

    &__price{
        // background-color: pink;
        margin-top: 20px;
        @include flexRow(center);
        align-items: center;
        color: $black;
    }

    &__price-accent{
        color: $accent;
        margin: 0px 5px 0px 5px;
        font-size: 22px;
    }

    &__cat-img-block{
        width: 270px;
        height: 100%;
        // background-color: aqua;
        @include flexRow(center);
        align-items: center;
    }

    &__cat-img{
        transition: 0.3s;
        max-width: 100%;
        max-height: 100%;
    }
    

    &__form-block{
        // width: calc(100% - 20px);
        width: 100%;
        // padding: 10px;
        border-radius: 5px;
        // height: auto;
        // background-color: red;
        position: relative;
        overflow: hidden;
        margin-top: 100px;
        box-shadow: 0px 0px 4px lightgray;
        background-color: darken(white, 3%);
        @include flexColumn(flex-start);
        z-index: 1;
        &::before{
            position: absolute;
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            // background-color: black;
            background-image: url(./../src/img/repair-1.jpg);
            background-attachment: fixed;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: 0%;
            left: 0px;
            top: 0px;
            // right: 0px;
        }
    }
    
    &__subform-container{
        // position: absolute;
        z-index: 1;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        padding: 10px;
        @include flexColumn(flex-start);
        background-color: rgba(0, 0, 0, 0.459);
        left: 0px;
        top: 0px;
    }

    &__subform-header{
        text-align: center;
        width: 100%;
        // background-color: yellow;
        font-size: 36px;
        color: white;
    }

    &__subform-text{
        text-align: center;
        width: 100%;
        // background-color: yellow;
        font-size: 24px;
        margin-top: 10px;
        color: white;
    }

    &__subform{
        width: 100%;
        height: 75px;
        @include flexRow(center);
        align-items: center;
        // background-color: plum;
        margin-top: 15px;
        flex-wrap: wrap;
    }

    &__subform-input{
        width: 210px;
        padding: 0px 5px 0px 5px;
        height: 35px;
        background-color: white;
        border: 1px solid black;
        margin: 0px 10px 0px 10px;
        outline: none;
        border-radius: 5px;
    }

    &__subform-button{
        width: 200px;
        height: 37px;
        background-color: $accent;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        margin: 0px 10px 0px 10px;
    }

    &__subform-error{
        width: 100%;
        height: 20px;
        margin-top: 5px;
        text-align: center;
        color: white;
    }

    &__articles-block{
        width: 100%;
        // height: 500px;
        margin-top: 100px;
        // background-color: red;
        @include flexColumn(flex-start);
    }

    &__articles-header{
        width: 100%;
        // background-color: yellow;
        font-size: 36px;
    }

    &__articles-container{
        @include flexRow(center);
        flex-wrap: wrap;
        // background-color: yellow;
        margin-top: 20px;
        align-items: flex-start;
    }

    &__article{
        width: 400px;
        height: 180px;
        margin: 15px;
        // background-color: pink;
        border-radius: 3px;
        @include flexColumn(flex-start);
        position: relative;
        overflow: hidden;
        &:hover{
            .cat-section__article-cover{
                background-color: rgba(0, 0, 0, 0.5);
            }

            .cat-section__article-img{
                transform: scale(1.1);
            }
        }
    }

    &__article-img-block{
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: plum;
        border-radius: 3px;
        overflow: hidden;
        left: 0px;
        top: 0px;
    }

    &__article-img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
    }

    &__article-cover{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.35);
        transition: 0.3s;
        left: 0px;
        top: 0px;
    }

    &__article-content-block{
        position: absolute;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        padding: 15px;
        // background-color: plum;
        @include flexColumn(center);
        text-align: center;
    }

    &__article-header{
        color: white;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
        // background-color: orange;
        height: 25px;
    }

    &__advices-block{
        width: 100%;
        // height: 500px;
        margin-top: 100px;
        // background-color: red;
        @include flexColumn(flex-start);
        // position: relative;
    }

    &__advices-header{
        width: 100%;
        // background-color: yellow;
        font-size: 36px;
    }

    &__advices-container{
        @include flexRow(center);
        flex-wrap: wrap;
        // background-color: yellow;
        margin-top: 20px;
        align-items: flex-start;
    }

    &__advice{
        width: 100%;
        // min-height: 280px;
        margin: 15px 0px 15px 0px;
        // background-color: pink;
        border-radius: 3px;
        @include flexColumn(flex-start);
        position: relative;
        overflow: hidden;
        text-decoration: none;
        &:hover{
            .cat-section__advice-content-block{
                background-color: rgba(0, 0, 0, 0.7);
            }

            .cat-section__advice-img{
                transform: scale(1.1);
            }
        }
    }

    &__advice-img-block{
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: red;
        border-radius: 3px;
        overflow: hidden;
        left: 0px;
        top: 0px;
    }

    &__advice-img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
    }

    &__advice-content-block{
        // position: absolute;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        padding: 15px;
        // background-color: plum;
        background-color: rgba(0, 0, 0, 0.6);
        @include flexColumn(center);
        text-align: center;
        top: 0px;
        z-index: 1;
    }

    &__advice-header{
        color: white;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        // margin-bottom: 15px;
        // background-color: orange;
        @include flexColumn(center);
        text-align: center;
        // height: 25px;
        height: auto;
    }
}

.work-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        // height: 1000px;
        // background-color: yellow;
        @include flexColumn(flex-start);
        // border-radius: 5px;
    }

    &__header-block{
        width: 100%;
        height: 400px;
        // background-color: pink;
        border-radius: 10px;
        @include flexRow(space-between);
        margin-bottom: 15px;
    }

    &__img-block{
        padding: 10px;
        width: 300px;
        height: 380px;
        // background-color: plum;
        @include flexColumn(center);
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
    }

    &__img{
        max-width: 100%;
        max-height: 100%;
    }

    &__content-block{
        width: calc(100% - 400px);
        height: 100%;
        // background-color: plum;
        border-radius: 10px;
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        // text-align: center;
    }

    &__header{
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        // height: 100%;
        // background-color: plum;
        // border-radius: 10px;
        // background-color: darken(white, 3%);
        // box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
        font-size: 36px;
        font-weight: 400;
    }

    &__subheader{
        // width: 100%;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        // height: 100%;
        // background-color: plum;
        // border-radius: 10px;
        // background-color: darken(white, 3%);
        // box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        margin-top: 20px;
    }
    
    &__form-header{
        text-decoration: underline;
        width: 100%;
        // height: 100%;
        // background-color: plum;
        // border-radius: 10px;
        // background-color: darken(white, 3%);
        // box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        margin-top: 20px;
    }

    &__form{
        width: 100%;
        height: 75px;
        @include flexRow(center);
        align-items: center;
        // background-color: plum;
        margin-top: 15px;
        flex-wrap: wrap;
    }

    &__form-input{
        width: 210px;
        padding: 0px 5px 0px 5px;
        height: 35px;
        background-color: white;
        border: 1px solid black;
        margin: 0px 10px 0px 10px;
        outline: none;
        border-radius: 5px;
    }

    &__form-button{
        width: 200px;
        height: 37px;
        background-color: $accent;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: white;
    }

    &__form-error{
        width: 100%;
        height: 20px;
        margin-top: 5px;
        text-align: center;
        color: $accent;
    }


    &__price-block{
        width: calc(100% - 20px);
        padding: 10px;
        background-color: $accent;
        @include flexRow(space-around);
        margin-top: 30px;
        border-radius: 5px;
        box-shadow: 0px 0px 4px lightgray;
    }
    
    &__work-name,
    &__work-price,
    &__work-time,
    &__work-warranty{
        font-size: 20px;
        color: white;
        padding: 5px;
        background-color: darken($accent, 10%);
        border-radius: 5px;
        text-align: center;
        margin: 10px;
        // margin: 10px;
    }

    
    &__errors-block{
        width: 100%;
        // height: 500px;
        // background-color: red;
        margin-top: 30px;
        @include flexColumn(flex-start);
    }
    
    &__error-header-block{
        width: calc(100% - 20px);
        min-height: 50px;
        padding: 10px 10px 10px 10px;
        background-color: $accent;
        @include flexRow(space-between);
        box-shadow: 0px 0px 4px lightgray;
        // background-color: darken(white, 3%);
        border-radius: 5px;
        align-items: center;
        // overflow: hidden;
    }

    &__error-header-block_active{
        border-radius: 5px;
    }
    
    &__error-header{
        // background-color: pink;
        font-size: 36px;
        color: white;
    }

    &__header-button{
        width: 35px;
        height: 35px;
        // background-color: lighten($accent, 10%);
        background-color: white;
        @include flexRow(center);
        align-items: center;
        border-radius: 40px;
        cursor: pointer;
        transition: 0.3s;
        svg{
            width: 20px;
            height: 20px;
            rect{
                transition: 0.3s;
            }
        }
        &:hover{
            svg{
                rect{
                    fill: $accent;
                }
            }
        }
    }

    &__header-button_active{
        svg{
            transform: rotate(45deg);
        }
    }

    &__errors-list{
        width: 100%;
        // background-color: red;
        @include flexColumn(flex-start);
        align-items: center;
        margin-top: 10px;
        height: 0px;
        overflow: hidden;
    }
    
    &__line{
        margin-bottom: 10px;
        width: calc(100% - 40px);
        padding: 20px 10px 20px 10px;
        @include flexRow(space-between);
        text-decoration: none;
        color: white;
        background-color: lighten($accent, 5%);
        border-radius: 5px;
        font-size: 18px;
        transition: 0.3s;
        &:not(:first-child){
            cursor: pointer;
            transition: 0.3s;
            &:hover{
                background-color: lighten($accent, 10%);
                box-shadow: 0px 0px 4px lightgray;
            }
        }
    }

    &__name{
        padding: 10px;
        @include flexColumn(center);
        color: white;
        width: 65%;
    }

    &__price{
        text-align: center;
        font-size: 15px;
        width: calc(10% - 20px);
        background-color: darken($accent, 5%);
        padding: 10px;
        border-radius: 5px;
        @include flexColumn(center);
    }
    
    &__time{
        @include flexColumn(center);
        font-size: 15px;
        text-align: center;
        width: calc(10% - 20px);
        background-color: darken($accent, 5%);
        padding: 10px;
        border-radius: 5px;
    }
    
    &__warranty{
        @include flexColumn(center);
        font-size: 15px;
        text-align: center;
        width: calc(10% - 20px);
        background-color: darken($accent, 5%);
        padding: 10px;
        border-radius: 5px;
    }

    &__brands-block{
        width: 100%;
        // height: 500px;
        // background-color: red;
        margin-top: 100px;
        @include flexColumn(flex-start);
    }

    &__brands-header{
        width: 100%;
        // height: 50px;
        // background-color: yellow;
        font-size: 36px;
    }

    &__brands-container{
        width: 100%;
        // height: 500px;
        // background-color: yellow;
        margin-top: 20px;
        height: 250px;
        overflow: hidden;
        @include flexRow(center);
        align-items: flex-start;
        flex-wrap: wrap;
    }

    &__brand{
        width: 200px;
        min-height: 80px;
        // background-color: pink;
        box-shadow: 0px 0px 4px lightgray;
        background-color: darken(white, 3%);
        padding: 10px;
        border-radius: 5px;
        text-decoration: none;
        color: black;
        @include flexColumn(center);
        text-align: center;
        font-size: 22px;
        margin: 15px;
        transition: 0.3s;
        &:hover{
            background-color: $accent;
            color: white;
        }
    }

    &__button-block{
        width: 100%;
        height: 50px;
        margin-top: 20px;
        // background-color: pink;
        @include flexRow(center);
        align-items: center;
    }

    &__show-button{
        width: 250px;
        height: 40px;
        background-color: $accent;
        border-radius: 5px;
        box-shadow: 0px 0px 4px lightgray;
        text-align: center;
        color: white;
        @include flexColumn(center);
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: lighten($accent, 10%);
            // background-color: darken(white, 15%);
        }
    }

    &__form-block{
        // width: calc(100% - 20px);
        width: 100%;
        // padding: 10px;
        border-radius: 5px;
        // height: auto;
        // background-color: red;
        position: relative;
        overflow: hidden;
        margin-top: 100px;
        box-shadow: 0px 0px 4px lightgray;
        background-color: darken(white, 3%);
        @include flexColumn(flex-start);
        z-index: 1;
        &::before{
            position: absolute;
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            // background-color: black;
            background-image: url(./../src/img/repair-1.jpg);
            background-attachment: fixed;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: 0%;
            left: 0px;
            top: 0px;
            // right: 0px;
        }
    }
    
    &__subform-container{
        // position: absolute;
        z-index: 1;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        padding: 10px;
        @include flexColumn(flex-start);
        background-color: rgba(0, 0, 0, 0.459);
        left: 0px;
        top: 0px;
    }

    &__subform-header{
        text-align: center;
        width: 100%;
        // background-color: yellow;
        font-size: 36px;
        color: white;
    }

    &__subform-text{
        text-align: center;
        width: 100%;
        // background-color: yellow;
        font-size: 24px;
        margin-top: 10px;
        color: white;
    }

    &__subform{
        width: 100%;
        height: 75px;
        @include flexRow(center);
        align-items: center;
        // background-color: plum;
        margin-top: 15px;
        flex-wrap: wrap;
    }

    &__subform-input{
        width: 210px;
        padding: 0px 5px 0px 5px;
        height: 35px;
        background-color: white;
        border: 1px solid black;
        margin: 0px 10px 0px 10px;
        outline: none;
        border-radius: 5px;
    }

    &__subform-button{
        width: 200px;
        height: 37px;
        background-color: $accent;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        margin: 0px 10px 0px 10px;
    }

    &__subform-error{
        width: 100%;
        height: 20px;
        margin-top: 5px;
        text-align: center;
        color: white;
    }

    &__articles-block{
        width: 100%;
        // height: 500px;
        margin-top: 100px;
        // background-color: red;
        @include flexColumn(flex-start);
    }

    &__articles-header{
        width: 100%;
        // background-color: yellow;
        font-size: 36px;
    }

    &__articles-container{
        @include flexRow(center);
        flex-wrap: wrap;
        // background-color: yellow;
        margin-top: 20px;
        align-items: flex-start;
    }

    &__article{
        margin: 12px;
        width: 300px;
        height: 300px;
        border-radius: 315px;
        // background-color: plum;
        position: relative;
        margin: 12px;
        // border-radius: 3px;
        overflow: hidden;
        text-decoration: none;
        background-color: $accent;
        transition: 0.2s;
        @include flexRow(center);
        align-items: center;
        // box-shadow: 0px 0px 10px darken($accent, 5%);
        &:hover{
            box-shadow: 0px 0px 15px darken($accent, 5%);
            // top: -3px;
        }
    }


    &__article-content-block{
        // width: calc(100% - 250px);
        // height: calc(100% - 60px);
        width: 275px;
        height: 275px;
        border-radius: 275px;
        // padding: 30px;
        // background-color: rgb(255, 166, 0);
        // background-color: rgba(0, 0, 0, 0.308);
        position: absolute;
        @include flexColumn(center);
        // background-color: pink;
        background-color: darken($accent, 5%);
        box-shadow: 0px 0px 10px darken($accent, 5%);
        overflow: hidden;
        // z-index: 2;
    }

    &__stat-block{
        width: 100%;
        height: 20px;
        // background-color: white;
        border-radius: 5px;
        @include flexRow(center);
        // background-color: plum;
        margin-top: 10px;
    }

    &__time-block{
        width: 80px;
        margin: 0px 5px 0px 5px;
        height: 20px;
        background-color: white;
        @include flexRow(center);
        align-items: center;
        border-radius: 5px;
        color: black;
    }

    &__stats-text{
        margin-left: 5px;
    }

    &__article-header{
        color: white;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        // background-color: plum;
        height: 100px;
        @include flexColumn(center);
        align-items: center;
        width: calc(100% - 20px);
        font-weight: 500;
        letter-spacing: 0.7px;
        padding: 0px 10px 0px 10px;


    }
}

.prices-section{
    width: 100%;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__cats-container{
        width: 100%;
        // height: 430px;
        // background-color: pink;
        margin-top: 40px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__cat{
        margin: 12px;
        width: 245px;
        box-shadow: 0px 0px 4px lightgray;
        background-color: darken(white, 3%);
        height: 205px;
        padding: 10px;
        // background-color: plum;
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        text-decoration: none;
        transition: 0.2s;
        @include flexColumn(flex-start);
        align-items: center;
        &:hover{
            // top: -3px;
            box-shadow: 0px 0px 10px rgb(177, 177, 177);
            .prices-section__img{
                transform: scale(1.05);
            }
            .prices-section__cat-name{
                color: $accent;
            }
        }
    }

    &__img-block{
        width: calc(100% - 20px);
        height: 150px;
        // background-color: orange;
        @include flexRow(center);
        align-items: center;
    }

    &__img{
        max-width: 100%;
        max-height: 100%;
        transition: 0.2s;
        // background-color: aqua;
    }

    &__cat-name{
        width: 100%;
        min-height: 40px;
        margin-top: 10px;
        // background-color: orange;
        text-align: center;
        @include flexColumn(center);
        color: black;
    }
    
}

.price-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        // height: 1000px;
        // background-color: yellow;
        @include flexColumn(flex-start);
        // border-radius: 5px;
    }

    &__header-block{
        width: 100%;
        height: 400px;
        // background-color: pink;
        border-radius: 10px;
        @include flexRow(space-between);
        margin-bottom: 15px;
    }

    &__img-block{
        padding: 10px;
        width: 300px;
        height: 380px;
        // background-color: plum;
        @include flexColumn(center);
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
    }

    &__img{
        max-width: 100%;
        max-height: 100%;
    }

    &__content-block{
        width: calc(100% - 400px);
        height: 100%;
        // background-color: plum;
        border-radius: 10px;
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        // text-align: center;
    }

    &__header{
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        // height: 100%;
        // background-color: plum;
        // border-radius: 10px;
        // background-color: darken(white, 3%);
        // box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
        font-size: 36px;
        font-weight: 400;
    }

    &__subheader{
        // width: 100%;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        // height: 100%;
        // background-color: plum;
        // border-radius: 10px;
        // background-color: darken(white, 3%);
        // box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        margin-top: 20px;
    }
    
    &__form-header{
        text-decoration: underline;
        width: 100%;
        // height: 100%;
        // background-color: plum;
        // border-radius: 10px;
        // background-color: darken(white, 3%);
        // box-shadow: 0px 0px 4px lightgray;
        @include flexColumn(center);
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        margin-top: 20px;
    }

    &__form{
        width: 100%;
        height: 75px;
        @include flexRow(center);
        align-items: center;
        // background-color: plum;
        margin-top: 15px;
        flex-wrap: wrap;
    }

    &__form-input{
        width: 210px;
        padding: 0px 5px 0px 5px;
        height: 35px;
        background-color: white;
        border: 1px solid black;
        margin: 0px 10px 0px 10px;
        outline: none;
        border-radius: 5px;
    }

    &__form-button{
        width: 200px;
        height: 37px;
        background-color: $accent;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: white;
    }

    &__form-error{
        width: 100%;
        height: 20px;
        margin-top: 5px;
        text-align: center;
        color: $accent;
    }
    
    &__errors-block{
        width: 100%;
        // height: 500px;
        // background-color: red;
        margin-top: 30px;
        @include flexColumn(flex-start);
    }
    
    &__error-header-block{
        width: calc(100% - 20px);
        min-height: 50px;
        padding: 10px 10px 10px 10px;
        background-color: $accent;
        @include flexRow(space-between);
        box-shadow: 0px 0px 4px lightgray;
        // background-color: darken(white, 3%);
        border-radius: 5px;
        align-items: center;
        // overflow: hidden;
    }

    &__error-header-block_active{
        border-radius: 5px;
    }
    
    &__error-header{
        // background-color: pink;
        font-size: 36px;
        color: white;
    }

    &__header-button{
        width: 35px;
        height: 35px;
        // background-color: lighten($accent, 10%);
        background-color: white;
        @include flexRow(center);
        align-items: center;
        border-radius: 40px;
        cursor: pointer;
        transition: 0.3s;
        svg{
            width: 20px;
            height: 20px;
            rect{
                transition: 0.3s;
            }
        }
        &:hover{
            svg{
                rect{
                    fill: $accent;
                }
            }
        }
    }

    &__header-button_active{
        svg{
            transform: rotate(45deg);
        }
    }

    &__errors-list{
        width: 100%;
        // background-color: red;
        @include flexColumn(flex-start);
        align-items: center;
        margin-top: 10px;
        height: 0px;
        overflow: hidden;
    }
    
    &__line{
        margin-bottom: 10px;
        width: calc(100% - 40px);
        padding: 20px 10px 20px 10px;
        @include flexRow(space-between);
        text-decoration: none;
        color: white;
        background-color: lighten($accent, 5%);
        border-radius: 5px;
        font-size: 18px;
        transition: 0.3s;
        &:not(:first-child){
            cursor: pointer;
            transition: 0.3s;
            &:hover{
                background-color: lighten($accent, 10%);
                box-shadow: 0px 0px 4px lightgray;
            }
        }
    }

    &__name{
        padding: 10px;
        @include flexColumn(center);
        color: white;
        width: 65%;
    }

    &__price{
        text-align: center;
        font-size: 15px;
        width: calc(10% - 20px);
        background-color: darken($accent, 5%);
        padding: 10px;
        border-radius: 5px;
        @include flexColumn(center);
    }
    
    &__time{
        @include flexColumn(center);
        font-size: 15px;
        text-align: center;
        width: calc(10% - 20px);
        background-color: darken($accent, 5%);
        padding: 10px;
        border-radius: 5px;
    }
    
    &__warranty{
        @include flexColumn(center);
        font-size: 15px;
        text-align: center;
        width: calc(10% - 20px);
        background-color: darken($accent, 5%);
        padding: 10px;
        border-radius: 5px;
    }

    &__brands-block{
        width: 100%;
        // height: 500px;
        // background-color: red;
        margin-top: 100px;
        @include flexColumn(flex-start);
    }

    &__brands-header{
        width: 100%;
        // height: 50px;
        // background-color: yellow;
        font-size: 36px;
    }

    &__brands-container{
        width: 100%;
        // height: 500px;
        // background-color: yellow;
        margin-top: 20px;
        height: 250px;
        overflow: hidden;
        @include flexRow(center);
        align-items: flex-start;
        flex-wrap: wrap;
    }

    &__brand{
        width: 200px;
        min-height: 80px;
        // background-color: pink;
        box-shadow: 0px 0px 4px lightgray;
        background-color: darken(white, 3%);
        padding: 10px;
        border-radius: 5px;
        text-decoration: none;
        color: black;
        @include flexColumn(center);
        text-align: center;
        font-size: 22px;
        margin: 15px;
        transition: 0.3s;
        &:hover{
            background-color: $accent;
            color: white;
        }
    }

    &__button-block{
        width: 100%;
        height: 50px;
        margin-top: 20px;
        // background-color: pink;
        @include flexRow(center);
        align-items: center;
    }

    &__show-button{
        width: 250px;
        height: 40px;
        background-color: $accent;
        border-radius: 5px;
        box-shadow: 0px 0px 4px lightgray;
        text-align: center;
        color: white;
        @include flexColumn(center);
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: lighten($accent, 10%);
            // background-color: darken(white, 15%);
        }
    }

    &__form-block{
        // width: calc(100% - 20px);
        width: 100%;
        // padding: 10px;
        border-radius: 5px;
        // height: auto;
        // background-color: red;
        position: relative;
        overflow: hidden;
        margin-top: 100px;
        box-shadow: 0px 0px 4px lightgray;
        background-color: darken(white, 3%);
        @include flexColumn(flex-start);
        z-index: 1;
        &::before{
            position: absolute;
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            // background-color: black;
            background-image: url(./../src/img/repair-1.jpg);
            background-attachment: fixed;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: 0%;
            left: 0px;
            top: 0px;
            // right: 0px;
        }
    }
    
    &__subform-container{
        // position: absolute;
        z-index: 1;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        padding: 10px;
        @include flexColumn(flex-start);
        background-color: rgba(0, 0, 0, 0.459);
        left: 0px;
        top: 0px;
    }

    &__subform-header{
        text-align: center;
        width: 100%;
        // background-color: yellow;
        font-size: 36px;
        color: white;
    }

    &__subform-text{
        text-align: center;
        width: 100%;
        // background-color: yellow;
        font-size: 24px;
        margin-top: 10px;
        color: white;
    }

    &__subform{
        width: 100%;
        height: 75px;
        @include flexRow(center);
        align-items: center;
        // background-color: plum;
        margin-top: 15px;
        flex-wrap: wrap;
    }

    &__subform-input{
        width: 210px;
        padding: 0px 5px 0px 5px;
        height: 35px;
        background-color: white;
        border: 1px solid black;
        margin: 0px 10px 0px 10px;
        outline: none;
        border-radius: 5px;
    }

    &__subform-button{
        width: 200px;
        height: 37px;
        background-color: $accent;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        margin: 0px 10px 0px 10px;
    }

    &__subform-error{
        width: 100%;
        height: 20px;
        margin-top: 5px;
        text-align: center;
        color: white;
    }

    &__articles-block{
        width: 100%;
        // height: 500px;
        margin-top: 100px;
        // background-color: red;
        @include flexColumn(flex-start);
    }

    &__articles-header{
        width: 100%;
        // background-color: yellow;
        font-size: 36px;
    }

    &__articles-container{
        @include flexRow(center);
        flex-wrap: wrap;
        // background-color: yellow;
        margin-top: 20px;
        align-items: flex-start;
    }

    &__article{
        margin: 12px;
        width: 300px;
        height: 300px;
        border-radius: 315px;
        // background-color: plum;
        position: relative;
        margin: 12px;
        // border-radius: 3px;
        overflow: hidden;
        text-decoration: none;
        background-color: $accent;
        transition: 0.2s;
        @include flexRow(center);
        align-items: center;
        // box-shadow: 0px 0px 10px darken($accent, 5%);
        &:hover{
            box-shadow: 0px 0px 15px darken($accent, 5%);
            // top: -3px;
        }
    }


    &__article-content-block{
        // width: calc(100% - 250px);
        // height: calc(100% - 60px);
        width: 275px;
        height: 275px;
        border-radius: 275px;
        // padding: 30px;
        // background-color: rgb(255, 166, 0);
        // background-color: rgba(0, 0, 0, 0.308);
        position: absolute;
        @include flexColumn(center);
        // background-color: pink;
        background-color: darken($accent, 5%);
        box-shadow: 0px 0px 10px darken($accent, 5%);
        overflow: hidden;
        // z-index: 2;
    }

    &__stat-block{
        width: 100%;
        height: 20px;
        // background-color: white;
        border-radius: 5px;
        @include flexRow(center);
        // background-color: plum;
        margin-top: 10px;
    }

    &__time-block{
        width: 80px;
        margin: 0px 5px 0px 5px;
        height: 20px;
        background-color: white;
        @include flexRow(center);
        align-items: center;
        border-radius: 5px;
        color: black;
    }

    &__stats-text{
        margin-left: 5px;
    }

    &__article-header{
        color: white;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        // background-color: plum;
        height: 100px;
        @include flexColumn(center);
        align-items: center;
        width: calc(100% - 20px);
        font-weight: 500;
        letter-spacing: 0.7px;
        padding: 0px 10px 0px 10px;


    }
}

.contacts-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1060px;
        width: 100%;
        // height: 1000px;
        // background-color: yellow;
        @include flexColumn(flex-start);
        flex-wrap: wrap;
        // border-radius: 5px;
    }

    &__header{
        width: 100%;
        // background-color: plum;
        @include flexColumn(center);
        font-size: 36px;
        font-weight: 400;
    }

    &__content-block{
        width: 100%;
        // height: 500px;
        margin-top: 20px;
        // background-color: pink;
        @include flexRow(space-between);
        flex-wrap: nowrap;
    }

    &__contacts-block{
        max-width: 500px;
        width: 100%;
        max-height: 500px;
        height: 100%;
        // padding: 10px;
        border-radius: 5px;
        // background-color: plum;
        @include flexColumn(flex-start);
        align-items: flex-start;
        // background-color: darken(white, 10%);
    }

    &__item-link{
        // width: 100%;
        height: 30px;
        // background-color: orange;
        @include flexRow(flex-start);
        color: black;
        align-items: center;
        text-decoration: none;
        margin-bottom: 15px;
        font-size: 18px;
        svg{
            width: 30px;
            height: 30px;
            // background-color: aqua;
            @include flexRow(center);
            align-items: center;
            margin-right: 5px;
            path{
                fill: $accent;
            }
        }
        &:hover{
            text-decoration: underline;
        }
    }

    &__item{
        width: 100%;
        min-height: 30px;
        // background-color: orange;
        @include flexRow(flex-start);
        color: black;
        align-items: center;
        text-decoration: none;
        margin-bottom: 10px;
        font-size: 18px;
        svg{
            width: 30px;
            height: 30px;
            // background-color: aqua;
            @include flexRow(center);
            align-items: center;
            margin-right: 5px;
            path{
                fill: $accent;
            }
        }
    }

    &__sep{
        width: 100%;
        height: 4px;
        background-color: $accent;
        margin: 10px 0px 10px 0px;
        border-radius: 5px;
    }

    &__soc-header{
        width: 100%;
        font-size: 20px;
        // background-color: pink;
        margin-bottom: 5px;
    }

    &__soc-block{
        // width: 120px;
        // height: 45px;
        // background-color: lighten($black, 10%);
        // background-color: pink;
        z-index: 1;
        @include flexRow(flex-start);
        margin-bottom: 20px;
    }

    &__soc-button{
        width: 40px;
        height: 40px;
        // border: 1px solid $light-gray;
        background-color: orange;
        // margin: 0px 5px 0px 5px;
        margin-right: 10px;
        @include flexRow(center);
        align-items: center;

        svg{
            width: 15px;
            height: 15px;
            path{
                fill: white;
            }
        }

        &:hover{
            background-color: lighten($accent, 5%);
            border-color: white;
            svg{
                path{
                    fill: white;
                }
            }
        }
        // width: 120px;
    }

    &__subscribe-button{
        // width: 300px;
        // height: 45px;
        padding: 15px 45px 15px 15px;
        background-color: rgb(73, 73, 73);
        border-radius: 3px;
        text-decoration: none;
        color: white;
        font-size: 13px;
        // margin-bottom: 35px;
        position: relative;
        // margin-top: 20px;
        cursor: pointer;
        svg{
            position: absolute;
            right: 18px;
            path{
                fill: white;
            }
        }
        background-position: center;
        transition: background 0.8s;

        &:hover {
            background: $accent radial-gradient(circle, transparent 1%, $accent  1%) center/15000%;
        }

        &:active {
            background-color: lighten($accent, 10%);
            background-size: 100%;
            transition: background 0s;
        }
    }
    
    &__map-block{
        max-width: 480px;
        width: 100%;
        height: 480px;
        background-color: $accent;
        border-radius: 5px;
        padding: 10px;

    }
}

.about-section{
    width: 100%;
    // height: 500px;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        // height: 1000px;
        // background-color: yellow;
        @include flexColumn(flex-start);
        flex-wrap: wrap;
        // border-radius: 5px;
    }

    &__header{
        width: 100%;
        // background-color: plum;
        @include flexColumn(center);
        font-size: 36px;
        font-weight: 400;
    }

    &__content-block{
        width: 100%;
        // height: 500px;
        margin-top: 20px;
        // background-color: pink;
        @include flexColumn(flex-start);
        align-items: flex-start;
        flex-wrap: nowrap;
    }

    &__main-info{
        width: calc(100% - 30px);
        padding: 15px;
        background-color: $accent;
        margin-bottom: 20px;
        border-radius: 5px;
        color: white;
        font-size: 18px;
    }

    &__cat-name{
        // background-color: plum;
        margin-left: 20px;
        margin-top: 5px;
        @include flexRow(flex-start);
        &:hover{
            .about-section__cat-link{
                opacity: 1;
            }
        }
    }

    &__cat-link{
        margin-left: 10px;
        text-decoration: none;
        color: $accent;
        opacity: 0;
        transition: 0.3s;
    }

    strong{
        color: $accent;
        font-size: 34px;
    }

    &__sep{
        width: 100%;
        height: 4px;
        background-color: $accent;
        border-radius: 5px;
        margin: 20px 0px 20px 0px;
    }

    &__end-text{
        width: calc(100% - 30px);
        padding: 15px;
        background-color: $accent;
        margin-bottom: 20px;
        border-radius: 5px;
        color: white;
        font-size: 18px;
    }

    &__form-block{
        width: 100%;
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        margin-top: 40px;
        box-shadow: 0px 0px 4px lightgray;
        background-color: darken(white, 3%);
        @include flexColumn(flex-start);
        z-index: 1;
        &::before{
            position: absolute;
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            // background-color: black;
            background-image: url(./../src/img/repair-1.jpg);
            background-attachment: fixed;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: 0%;
            left: 0px;
            top: 0px;
            // right: 0px;
        }
    }
    
    &__subform-container{
        // position: absolute;
        z-index: 1;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        padding: 10px;
        @include flexColumn(flex-start);
        background-color: rgba(0, 0, 0, 0.459);
        left: 0px;
        top: 0px;
    }

    &__subform-header{
        text-align: center;
        width: 100%;
        // background-color: yellow;
        font-size: 36px;
        color: white;
    }

    &__subform-text{
        text-align: center;
        width: 100%;
        // background-color: yellow;
        font-size: 24px;
        margin-top: 10px;
        color: white;
    }

    &__subform{
        width: 100%;
        height: 75px;
        @include flexRow(center);
        align-items: center;
        // background-color: plum;
        margin-top: 15px;
        flex-wrap: wrap;
    }

    &__subform-input{
        width: 210px;
        padding: 0px 5px 0px 5px;
        height: 35px;
        background-color: white;
        border: 1px solid black;
        margin: 0px 10px 0px 10px;
        outline: none;
        border-radius: 5px;
    }

    &__subform-button{
        width: 200px;
        height: 37px;
        background-color: $accent;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        margin: 0px 10px 0px 10px;
    }

    &__subform-error{
        width: 100%;
        height: 20px;
        margin-top: 5px;
        text-align: center;
        color: white;
    }
}

.big-reviews-section{
    width: 100%;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);
    
    &__container{
        max-width: 1360px;
        width: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__counter{
        // background-color: pink;
        margin-top: 20px;
        font-size: 24px;
    }
    
    &__counter-value{
        // color: red;
        font-weight: 600;
    }
    
    &__reviews-container{
        width: calc(100% - 2px);
        // height: 430px;
        // background-color: pink;
        margin-top: 40px;
        @include flexRow(center);
        align-items: flex-start;
        flex-wrap: wrap;
    }
    
    &__reviews-block{
        // border-radius: 5px;
        // border: 1px solid lightgrey;
        // background-color: darken(white, 3%);
        // background-color: plum;
        width: calc(100% - 330px);
        @include flexColumn(flex-start);
        // align-items: center;
        // height: auto;
        // min-height: 300px;
    }

    &__review{
        width: calc(100% - 30px);
        // height: 470px;
        // background-color: orange;
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        border-radius: 5px;
        padding: 15px;
        @include flexColumn(flex-start);
        text-decoration: none;
        &:not(:last-child){
            // border-bottom: 1px solid lightgrey;
        }
        &:not(:first-child){
            margin-top: 20px;
        }
    }

    &__author-block{
        height: 20px;
        // background-color: orange;
        @include flexRow(space-between);
        align-items: center;
        margin-top: 10px;
        // color: yellowgreen;
    }

    &__author-container{
        // background-color: pink;
        font-size: 14px;
        @include flexRow(flex-start);
    }

    &__rating-block{
        // width: 175px;
        height: 100%;
        // background-color: aqua;
        @include flexRow(center);
        align-items: center;
    }

    &__star{
        width: 20px;
        height: 20px;
        margin-left: 5px;
    }

    &__star_active{
        path{
            fill: $accent;
        }
    }

    &__author-name{
        color: $accent;
        margin-right: 10px;
    }

    &__date{
        color: $accent;
    }

    &__review-header{
        // background-color: orange;
        margin-top: 20px;
        font-size: 18px;
        font-weight: 600;
        color: black;
    }

    &__review-text{
        width: calc(100% - 20px);
        margin-left: 20px;
        // background-color: orange;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        color: black;
    }

    &__form-block{
        width: 300px;
        // height: 400px;
        // background-color: plum;
        // background-color: darken(white, 3%);
        background-color: darken(white, 3%);
        box-shadow: 0px 0px 4px lightgray;
        // border: 1px solid lightgrey;
        margin-left: 20px;
        border-radius: 5px;
        overflow: hidden;
    }

    &__form{
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        padding: 15px;
        // background-color: orange;
        @include flexColumn(flex-start);
    }

    &__form-header{
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        // background-color: aqua;
        margin-bottom: 30px;
    }

    &__input{
        width: calc(100% - 12px);
        height: 40px;
        // background-color: aqua;
        margin-top: 10px;
        outline: none;
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding: 0px 0px 0px 10px;
    }

    &__stars-container{
        // background-color: plum;
        // margin-left: 40px;
        @include flexRow(flex-start);
        align-items: flex-end;
        // padding: 10px 0px;
        margin-top: 15px;
        width: 100%;
    }

    &__star-label{
        cursor: pointer;
        &:hover{
            svg{
                path{
                    fill: $accent;
                }
            }
        }
    }
    
    &__star-label_active{
        cursor: pointer;
        // margin-right: 13px;
        svg{
            path{
                fill: $accent;
            }
        }
    }

    &__star-label_checked{
        pointer-events: none;
    }
    
    &__star-checkbox{
        display: none;
    }
    
    &__star-checkbox:checked + &__star-pseudo svg path{
        fill: $accent;
    }

    .star-error{
        path{
            fill: $accent;
        }
    }
    
    &__star-pseudo{
        margin-right: 13px;
        // background-color: orange;
        width: 30px;
        height: 30px;
        svg{
            width: 100%;
            height: 100%;
            path{
                transition: 0.3s;
            }
        }
    }    

    &__textarea{
        width: calc(100% - 22px);
        height: 100px;
        // background-color: aqua;
        margin-top: 15px;
        outline: none;
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding: 10px;
        resize: none;
    }

    &__form-error{
        width: 100%;
        margin-top: 5px;
        color: $accent;
        text-align: center;
    }

    &__form-button{
        width: 100%;
        height: 40px;
        margin-top: 15px;
        background-color: black;
        border-radius: 5px;
        text-decoration: none;
        color: white;
        text-align: center;
        @include flexColumn(center);
        user-select: none;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: lighten(black, 20%);
        }
        &:active{
            transform: scale(0.9);
        }
    }
}

.big-cats-section{
    width: 100%;
    padding: 50px 0px 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1360px;
        width: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__articles-container{
        width: 100%;
        // height: 430px;
        // background-color: pink;
        margin-top: 40px;
        @include flexRow(center);
        flex-wrap: wrap;
    }


    &__cats-container{
        width: 100%;
        // height: 790px;
        // overflow: hidden;
        // background-color: pink;
        list-style: none;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__cat{
        // background-color: plum;
        // background-color: darken(white, 3%);
        background-color: white;
        margin: 15px;
        border-radius: 3px;
        // @include flexRow(space-between);
        transition: 0.3s;
        &:hover{
            // background-color: darken(white, 10%);
            box-shadow: 0px 0px 10px darken(white, 16%);
            .cats-section__img{
                transform: scale(1.1);
            }
        }
    }
    
    &__cat-link{
        width: 600px;
        height: 310px;
        padding: 25px;
        @include flexRow(space-between);
        // width: 100%;
        // height: 100%;
        // background-color: orange;
        text-decoration: none;
    }

    &__content-block{
        width: 320px;
        height: 100%;
        // background-color: aqua;
        @include flexColumn(center);
        align-items: center;
    }
    
    &__name{
        // margin-top: 100px;
        color: $black;
        font-size: 24px;
        font-weight: 400;
    }

    &__price{
        // background-color: pink;
        margin-top: 20px;
        @include flexRow(center);
        align-items: center;
        color: $black;
    }

    &__price-accent{
        color: $accent;
        margin: 0px 5px 0px 5px;
        font-size: 22px;
    }

    &__img-block{
        width: 270px;
        height: 100%;
        // background-color: aqua;
        @include flexRow(center);
        align-items: center;
    }

    &__img{
        transition: 0.3s;
        max-width: 100%;
        max-height: 100%;
    }

    &__button-block{
        width: 100%;
        height: 50px;
        margin-top: 20px;
        // background-color: pink;
        @include flexRow(center);
        align-items: center;
    }

    &__show-button{
        width: 250px;
        height: 40px;
        background-color: darken(white, 10%);
        border-radius: 5px;
        box-shadow: 0px 0px 4px lightgray;
        text-align: center;
        @include flexColumn(center);
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            box-shadow: 0px 0px 4px rgb(170, 170, 170);
            // background-color: darken(white, 15%);
        }
    }
    
}

.action-block{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.397);
    position: absolute;
    z-index: 99999;
    @include flexColumn(center);
    align-items: center;
    display: none;

    &__container{
        max-width: 250px;
        width: calc(100% - 80px);
        max-height: 350px;
        height: calc(100% - 110px);
        background-color: darken(white, 3%);
        @include flexColumn(flex-start);
        border-radius: 60px 5px 60px 5px;
        position: relative;
        padding: 40px;
    }


    &__close-action{
        cursor: pointer;
        width: 46px;
        height: 46px;
        // background-color: pink;
        position: absolute;
        right: 0px;
        top: 0px;
        @include flexRow(center);
        align-items: center;
        svg{
            transition: 0.3s;
            path{
                fill: $light-gray;
            }
        }
        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__header{
        width: 100%;
        height: 50px;
        // background-color: pink;
        font-size: 24px;
        font-weight: 400;
        // color: white;
        margin-bottom: 10px;
    }

    &__label{
        width: 100%;
        height: 75px;
        // background-color: pink;
        @include flexColumn(flex-start);
        margin-bottom: 20px;
    }

    &__input-name{
        font-size: 14px;
        // color: $light-gray;
        margin-bottom: 5px;
        // background-color: plum;
        height: 20px;
    }

    &__input{
        border: none;
        width: calc(100% - 22px);
        padding: 0px 10px 0px 10px;
        background-color: transparent;
        // background-color: plum;
        outline: none;
        height: 50px;
        border-radius: 3px;
        border: 1px solid $light-gray;
        background-color: white;
        transition: 0.3s;
        // color: white;
        &::placeholder{
            color: $light-gray;
        }

        &:focus{
            // background-color: darken($light-gray, 55%);
            border-color: black;
        }
    }

    &__error{
        width: 100%;
        height: 50px;
        // background-color: plum;
        margin-bottom: 10px;
        text-align: center;
        @include flexColumn(center);
        color: $accent;
        // display: none;
    }

    &__button{
        width: 100%;
        height: 50px;
        background-color: $accent;
        border-radius: 3px;
        text-align: center;
        @include flexColumn(center);
        align-items: center;
        text-decoration: none;
        color: white;
        cursor: pointer;
        background-position: center;
        transition: background 0.8s;
        box-shadow:  0px 0px 10px $accent;
        border: none;
        transition: 0.3s;

        &:hover {
            transform: scale(0.95);
            background: $accent radial-gradient(circle, transparent 1%, $accent  1%) center/15000%;
        }

        &:active {
            background-color: lighten($accent, 10%);
            background-size: 100%;
            transition: background 0s;
        }
    }
}

.error-block{
    width: 100vw;
    height: 500px;
    // background-color: red;
    // margin-bottom: -200px;
    @include flexColumn(center);
    align-items: center;

    svg{
        width: 300px;
        height: 300px;
        path{
            fill: $accent;
        }
    }

    &__message{
        font-size: 20px;
        @include flexRow(center);
        align-items: center;
        color: $accent;
    }

    &__link{
        margin-top: 20px;
        color: $accent;
        font-size: 24px;
        &:hover{
            text-decoration: none;
        }
    }
}

@media (max-width: 1360px){
    .same-block{
        &__advice{
            width: 320px;
            &:nth-child(even){
                .same-block__advice-img-block{
                    display: none;
                }

                .same-block__texts-block{
                    width: calc(100% - 20px);
                    padding: 10px;
                    margin-left: 0px;
                    margin-right: 0px;
                }
            }
        }

        &__advice-img-block{
            display: none;
        }

        &__texts-block{
            width: calc(100% - 20px);
            padding: 10px;
            margin-left: 0px;
            margin-right: 0px;
        }

        &__advice-number{
            text-align: center;
            height: unset;
        }

        &__advice-text{
            margin-left: 0px;
            margin-right: 0px;
            text-align: center;
            width: 100%;
            // background-color: yellow;
        }
    }

    .zero-sort{
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        text-align: center;
    }

    .section-header{
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        text-align: center;
    }
    
    .bread-block{
        // background-color: red;
        flex-wrap: wrap;
        justify-content: center;

        &__item{
            // background-color: yellow;
            margin: 5px;
        }

        &__link{
            text-align: center;
        }
    }

    .small-section-text{
        text-align: center;
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
    }

    .sort-block{
        // background-color: red;
        @include flexColumn(flex-start);
        flex-wrap: nowrap;
        min-height: unset;
        border-radius: 0px;
        background-color: $accent;
        // height: 500px;
    

        &__text{
            // background-color: yellow;
            width: 100%;
            height: unset;
        }
    }

    .main-section{
        height: 400px;
        width: 100%;
        flex-wrap: wrap;

        
        &__header{
            margin-top: 0px;
            color: white;
            text-align: center;
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
        }

        &__buttons-block{
            @include flexRow(center);
            flex-wrap: wrap;
            height: unset;
            min-height: 50px;
        }

        &__call-button{
            margin-right: 0px;
            margin: 12px;
        }

        &__req-button{
            margin-right: 0px;
            margin: 12px;
        }
    }

    .brands-line{
        // display: none;
        // background-color: red;
        height: unset;
        &__container{
            // background-color: yellow;
        }

        &__slide{
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            // background-color: pink;
        }

        &__control-button{
            // background-color: pink;
            margin: 10px;
        }
    }

    .cats-section{
        // display: none;
        // background-color: red;
        &__container{
            // background-color: yellow;
        }

        &__cats-container{
            // background-color: pink;
            align-items: center;
            @include flexRow(center);
        }

        &__cat{
            // background-color: unset;
        }

        &__cat-link{
            // margin-right: 0px;
            // background-color: plum;
            @include flexRow(center);
            align-items: center;
            flex-wrap: wrap;
            max-width: 600px;
            width: calc(100% - 20px);
            height: unset;
            padding: 10px;
        }
    }

    .sales-section{
        // display: none;
        // background-color: red;
        height: unset;


        &__container{
            // background-color: yellow;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__content-block{
            margin: 10px;
            width: 300px;
            // background-color: pink;
        }

        &__sale{
            margin: 10px;
            width: 300px;
            // background-color: pink;
        }
    }

    .sale-section{
        &__subform{
            height: unset;
        }

        &__subform-input{
            margin: 10px;
        }

        &__subform-button{
            width: 220px;
            background-color: $accent;
        }

        &__subform-error{
            color: black;
        }
    }

    .workers-section{
        // display: none;
        // background-color: red;

        &__container{
            // background-color: yellow;
        }

        &__header{
            text-align: center;
        }

        &__workers-container{
            height: unset;
            @include flexRow(center);
            flex-wrap: wrap;
            // background-color: pink;
        }

        &__worker{
            margin: 15px;
        }
    }

    .reviews-section{
        // display: none;
        // background-color: red;

        &__container{
            // background-color: yellow;
        }

        &__header-block{
            @include flexRow(center);
            align-items: center;
            flex-wrap: wrap;
            // background-color: pink;
            height: unset;
        }

        &__header{
            width: 100%;
            // background-color: plum;
            margin-bottom: 10px;
            text-align: center;
        }

        &__slider{
            // background-color: pink;
            width: calc(100% - 100px);
        }

        &__slide{
            // background-color: plum;
        }

        &__info-block{
            width: 100%;
            // background-color: rgb(0, 81, 255);
            height: unset;
            @include flexColumn(flex-start);
            align-items: center;
            padding: 0px;
        }

        &__author-block{
            width: 100%;
            // background-color: aqua;
            @include flexColumn(flex-start);
            height: unset;
        }

        &__date{
            text-align: center;
        }

        &__author{
            text-align: center;
        }

        &__rating-block{
            // background-color: aqua;
            margin-top: 10px;
            // width: 100%;
        }

        &__text{
            // background-color: blue;
            margin-top: 10px;
            text-align: center;
            height: unset;
            width: 100%;
            padding: 0px;
            &::before{
                display: none;
            }
        }
    }

    .advices-section{
        // display: none;
        // background-color: red;
        height: unset;

        &__container{
            // background-color: yellow;  
            flex-wrap: wrap;  
            @include flexRow(center);
            flex-wrap: wrap;
        }

        &__advice{
            max-width: 430px;
            width: 100%;
            margin: 10px;
        }
    }

    .advice-section{
        // background-color: red;

        &__stat-block{
            @include flexRow(center);
            align-items: center;
        }

        &__header-block{
            height: unset;
            @include flexColumn(flex-start);
        }

        &__stat-block{
            width: calc(100% - 20px);
        }

        &__header-container{
            width: calc(100% - 40px);
            border-radius: 0px;
            box-shadow: unset;
        }
    }

    .article-section{
        // background-color: red;

        &__container{
            // background-color: yellow;
            align-items: center;
        }

        &__header-block{
            // flex-wrap: wrap;
            height: unset;
            // background-color: pink;
            @include flexColumn(flex-start);
        }

        &__stat-block{
            padding: 0px;
            width: 100%;
            // order: 2;
            align-items: center;
            box-shadow: unset;
            border-radius: 0px;
        }

        &__stat-item{
            text-align: center;
        }

        &__header{
            width: calc(100% - 20px);
            padding: 10px 10px 10px 10px;
            text-align: center;
            box-shadow: 0px;
            font-size: 42px;
            background-color: $accent;
            color: white;
            border-radius: 0px;
        }

        &__article{
            border-radius: 0px;
            background-color: unset;
            box-shadow: none;
        }
    }

    .big-advices-section{
        &__advice{
            width: 320px;
            &:nth-child(even){
                .big-advices-section__img-block{
                    display: none;
                }

                .big-advices-section__texts-block{
                    width: calc(100% - 20px);
                    padding: 10px;
                    margin-left: 0px;
                    margin-right: 0px;
                }
            }
        }

        &__img-block{
            display: none;
        }

        &__texts-block{
            width: calc(100% - 20px);
            padding: 10px;
            margin-left: 0px;
            margin-right: 0px;
        }

        &__advice-number{
            text-align: center;
            height: unset;
        }

        &__advice-text{
            margin-left: 0px;
            margin-right: 0px;
            text-align: center;
            width: 100%;
            // background-color: yellow;
        }
    }

    .advices-section{
        &__header-block{
            height: unset;
            @include flexColumn(flex-start);
        }

        &__header{
            width: calc(100% - 20px);
            text-align: center;
        }

        &__buttons-block{
            width: 100%;
            @include flexRow(center);
            margin-top: 10px;
        }
        &__advice{
            width: 320px;
            &:nth-child(even){
                .advices-section__img-block{
                    display: none;
                }

                .advices-section__texts-block{
                    width: calc(100% - 20px);
                    padding: 10px;
                    margin-left: 0px;
                    margin-right: 0px;
                }
            }
        }

        &__img-block{
            display: none;
        }

        &__texts-block{
            width: calc(100% - 20px);
            padding: 10px;
            margin-left: 0px;
            margin-right: 0px;
        }

        &__advice-number{
            text-align: center;
            height: unset;
        }

        &__advice-text{
            margin-left: 0px;
            margin-right: 0px;
            text-align: center;
            width: 100%;
            // background-color: yellow;
        }
    }

    .big-questions-section{
        // background-color: red;

        &__counter{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
        }

        &__counter-value{
            color: $accent;
        }

        &__questions-container{
            // background-color: yellow;
            @include flexColumn(flex-start);
            width: 100%;
        }

        &__form-block{
            order: 1;
            margin-left: 0px;
            width: 100%;
            background-color: $accent;
            // background-color: white;
            border-radius: 0px;
            box-shadow: none;
            margin-bottom: 10px;
        }

        &__form-header{
            color: white;
        }

        &__form-error{
            color: black;
        }

        &__questions-block{
            // display: none;
            width: 100%;
            order: 2;
            // background-color: pink;
        }

        &__question{
            border-radius: 0px;
        }
    }

    .question-section{
        // background-color: red;

        &__reason-block{
            background-color: $accent;
            border-radius: 0px;
        }

        &__reason-header{
            width: 100%;
            text-align: center;
            color: white;
        }

        &__reason{
            width: 100%;
            text-align: center;
            color: white;
            word-wrap: break-word;
            // word-wrap: normal;
            margin-left: 0px;
        }

        &__question-block{
            border-radius: 0px;
        }

        &__answer-block{
            border-radius: 0px;
        }
    }

    .big-reviews-section{
        // background-color: red;

        &__counter{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
        }

        &__counter-value{
            color: $accent;
        }

        &__reviews-container{
            // background-color: yellow;
            @include flexColumn(flex-start);
            width: 100%;
        }

        &__form-block{
            order: 1;
            margin-left: 0px;
            width: 100%;
            background-color: $accent;
            // background-color: white;
            border-radius: 0px;
            box-shadow: none;
            margin-bottom: 10px;
        }

        &__form-header{
            color: white;
        }

        &__form-error{
            color: black;
        }

        &__star-checkbox:checked + &__star-pseudo svg path{
            fill: darken($accent, 15%);
        }

        &__reviews-block{
            // display: none;
            width: 100%;
            order: 2;
            // background-color: pink;
        }

        &__review{
            border-radius: 0px;
        }

        &__author-block{
            width: 100%;
            @include flexColumn(flex-start);
            align-items: center;
        }
    }

    .big-cats-section{
        // display: none;
        // background-color: red;
        &__container{
            // background-color: yellow;
        }

        &__cats-container{
            // background-color: pink;
            align-items: center;
            @include flexRow(center);
        }

        &__cat{
            // background-color: plum;
            width: calc(100% - 30px);
        }

        &__cat-link{
            // margin-right: 0px;
            background-color: darken(white, 3%);
            border-radius: 5px;
            @include flexRow(center);
            align-items: center;
            flex-wrap: wrap;
            max-width: unset;
            width: calc(100% - 20px);
            // width: 100%;
            height: unset;
            padding: 10px;
        }
    }

    .cat-section{
        // background-color: red;

        &__container{
            // background-color: yellow;
            @include flexColumn(flex-start);
        }

        &__header-block{
            // background-color: pink;
            height: unset;
            @include flexColumn(flex-start);
        }

        &__img-block{
            order: 2;
            width: calc(100% - 20px);
            @include flexRow(center);
            align-items: center;
        }

        &__img{
            max-width: 325px;
        }

        &__content-block{
            width: calc(100% - 20px);
            @include flexColumn(flex-start);
            background-color: $accent;
            border-radius: 0px;
            height: unset;
            padding: 10px;
        }

        &__header{
            color: white;
        }

        &__subheader{
            color: white;
        }

        &__form-header{
            color: white;
        }

        &__form{
            height: unset;
        }

        &__form-input{
            margin: 10px;
        }

        &__form-button{
            width: 220px;
            background-color: black;
        }

        &__form-error{
            color: black;
        }

        &__errors-block{
            // background-color: pink;
        }
        
        &__error-header-block{
            border-radius: 0px;
            // background-color: plum;
            height: unset;
            padding: 10px;
        }

        &__error-header{
            max-width: calc(100% - 45px);
            // background-color: orange;
            text-align: center;
            font-size: 32px;
            word-wrap: break-word
        }

        &__errors-list{
            // height: 2444px;
            // background-color: plum;
        }

        &__line{
            // background-color: orange;
            @include flexColumn(flex-start);
        }

        &__name{
            width: calc(100% - 20px);
            // background-color: aqua;
            text-align: center;
            margin-bottom: 10px;
        }
        
        &__price{
            width: calc(100% - 20px);
            // background-color: aqua;
            text-align: center;
            margin-bottom: 10px;
        }
        
        &__time{
            width: calc(100% - 20px);
            // background-color: aqua;
            text-align: center;
            margin-bottom: 10px;
        }
        
        &__warranty{
            width: calc(100% - 20px);
            // background-color: aqua;
            text-align: center;
            margin-bottom: 10px;
        }

        &__brands-header{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
            color: white;
            background-color: $accent;
        }

        &__subform-container{
            background-color: $accent;
        }

        &__form-block{
            border-radius: 0px;
            &::before{
                display: none;
            }
        }

        &__subform{
            height: unset;
        }

        &__subform-input{
            margin: 10px;
        }

        &__subform-button{
            width: 220px;
            background-color: black;
        }

        &__subform-error{
            color: black;
        }

        &__articles-header{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
            color: white;
            background-color: $accent;
        }

        &__advices-header{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
            color: white;
            background-color: $accent;
        }

        &__advice{
            width: 320px;
            &:nth-child(even){
                .cat-section__advice-img-block{
                    display: none;
                }

                .cat-section__texts-block{
                    width: calc(100% - 20px);
                    padding: 10px;
                    margin-left: 0px;
                    margin-right: 0px;
                }
            }
        }

        &__advice-img-block{
            display: none;
        }

        &__texts-block{
            width: calc(100% - 20px);
            padding: 10px;
            margin-left: 0px;
            margin-right: 0px;
        }

        &__advice-number{
            text-align: center;
            height: unset;
        }

        &__advice-text{
            margin-left: 0px;
            margin-right: 0px;
            text-align: center;
            width: 100%;
            // background-color: yellow;
        }
    }

    .work-section{
        // background-color: red;

        &__container{
            // background-color: yellow;
            @include flexColumn(flex-start);
        }

        &__header-block{
            // background-color: pink;
            height: unset;
            @include flexColumn(flex-start);
        }

        &__img-block{
            order: 2;
            width: calc(100% - 20px);
            @include flexRow(center);
            align-items: center;
        }

        &__img{
            max-width: 325px;
        }

        &__content-block{
            width: calc(100% - 20px);
            @include flexColumn(flex-start);
            background-color: $accent;
            border-radius: 0px;
            height: unset;
            padding: 10px;
        }

        &__header{
            color: white;
        }

        &__subheader{
            color: white;
        }

        &__form-header{
            color: white;
        }

        &__form{
            height: unset;
        }

        &__form-input{
            margin: 10px;
        }

        &__form-button{
            width: 220px;
            background-color: black;
        }

        &__form-error{
            color: black;
        }

        &__price-block{
            border-radius: 0px;
            @include flexColumn(flex-start);
        }

        &__errors-block{
            // background-color: pink;
        }
        
        &__error-header-block{
            border-radius: 0px;
            // background-color: plum;
            height: unset;
            padding: 10px;
        }

        &__error-header{
            max-width: calc(100% - 45px);
            // background-color: orange;
            text-align: center;
            font-size: 32px;
            word-wrap: break-word
        }

        &__errors-list{
            // height: 2444px;
            // background-color: plum;
        }

        &__line{
            // background-color: orange;
            @include flexColumn(flex-start);
        }

        &__name{
            width: calc(100% - 20px);
            // background-color: aqua;
            text-align: center;
            margin-bottom: 10px;
        }
        
        &__price{
            width: calc(100% - 20px);
            // background-color: aqua;
            text-align: center;
            margin-bottom: 10px;
        }
        
        &__time{
            width: calc(100% - 20px);
            // background-color: aqua;
            text-align: center;
            margin-bottom: 10px;
        }
        
        &__warranty{
            width: calc(100% - 20px);
            // background-color: aqua;
            text-align: center;
            margin-bottom: 10px;
        }

        &__brands-header{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
            color: white;
            background-color: $accent;
        }

        &__subform-container{
            background-color: $accent;
        }

        &__form-block{
            border-radius: 0px;
            &::before{
                display: none;
            }
        }

        &__subform{
            height: unset;
        }

        &__subform-input{
            margin: 10px;
        }

        &__subform-button{
            width: 220px;
            background-color: black;
        }

        &__subform-error{
            color: black;
        }

        &__articles-header{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
            color: white;
            background-color: $accent;
        }



        &__advices-header{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
            color: white;
            background-color: $accent;
        }
    }

    .price-section{
        // background-color: red;

        &__container{
            // background-color: yellow;
            @include flexColumn(flex-start);
        }

        &__header-block{
            // background-color: pink;
            height: unset;
            @include flexColumn(flex-start);
        }

        &__img-block{
            order: 2;
            width: calc(100% - 20px);
            @include flexRow(center);
            align-items: center;
        }

        &__img{
            max-width: 325px;
        }

        &__content-block{
            width: calc(100% - 20px);
            @include flexColumn(flex-start);
            background-color: $accent;
            border-radius: 0px;
            height: unset;
            padding: 10px;
        }

        &__header{
            color: white;
        }

        &__subheader{
            color: white;
        }

        &__form-header{
            color: white;
        }

        &__form{
            height: unset;
        }

        &__form-input{
            margin: 10px;
        }

        &__form-button{
            width: 220px;
            background-color: black;
        }

        &__form-error{
            color: black;
        }

        &__errors-block{
            // background-color: pink;
        }
        
        &__error-header-block{
            border-radius: 0px;
            // background-color: plum;
            height: unset;
            padding: 10px;
        }

        &__error-header{
            max-width: calc(100% - 45px);
            // background-color: orange;
            text-align: center;
            font-size: 32px;
            word-wrap: break-word
        }

        &__errors-list{
            // height: 2444px;
            // background-color: plum;
        }

        &__line{
            // background-color: orange;
            @include flexColumn(flex-start);
        }

        &__name{
            width: calc(100% - 20px);
            // background-color: aqua;
            text-align: center;
            margin-bottom: 10px;
        }
        
        &__price{
            width: calc(100% - 20px);
            // background-color: aqua;
            text-align: center;
            margin-bottom: 10px;
        }
        
        &__time{
            width: calc(100% - 20px);
            // background-color: aqua;
            text-align: center;
            margin-bottom: 10px;
        }
        
        &__warranty{
            width: calc(100% - 20px);
            // background-color: aqua;
            text-align: center;
            margin-bottom: 10px;
        }

        &__brands-header{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
            color: white;
            background-color: $accent;
        }

        &__subform-container{
            background-color: $accent;
        }

        &__form-block{
            border-radius: 0px;
            &::before{
                display: none;
            }
        }

        &__subform{
            height: unset;
        }

        &__subform-input{
            margin: 10px;
        }

        &__subform-button{
            width: 220px;
            background-color: black;
        }

        &__subform-error{
            color: black;
        }

        &__articles-header{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
            color: white;
            background-color: $accent;
        }

        &__advices-header{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
            color: white;
            background-color: $accent;
        }
    }

    .contacts-section{
        // background-color: red;

        &__container{
            // background-color: yellow;
        }

        &__header{
            // background-color: palevioletred;
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
        }

        &__content-block{
            // background-color: pink;
            @include flexColumn(flex-start);
        }

        &__contacts-block{
            // background-color: plum;
            align-items: center;
        }

        &__item{
            text-align: center;
            // background-color: orange;
            @include flexRow(center);
        }

        &__soc-header{
            text-align: center;
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
        }

        &__map-block{
            width: calc(100% - 20px);
            border-radius: 0px;
            height: 300px;
        }
    }

    .about-section{
        // background-color: red;

        &__container{
            // background-color: yellow;
        }

        &__header{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
        }

        strong{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
            text-align: center;
            word-wrap: break-word;
        }

        &__main-info{
            // border-radius: 0px;
        }

        &__content-block{
            width: calc(100% - 20px);
            padding: 0px 10px 0px 10px;
        }

        &__subform-container{
            background-color: $accent;
            border-radius: 5px;
        }

        &__form-block{
            border-radius: 0px;
            &::before{
                display: none;
            }
        }

        &__subform{
            height: unset;
        }

        &__subform-input{
            margin: 10px;
        }

        &__subform-button{
            width: 220px;
            background-color: black;
        }

        &__subform-error{
            color: black;
        }
    }
}
